<template>
  <dialog ref="dialog" class="gam-dialog" @close="hideDialog">
    <transition name="fade">
      <div v-if="visible" class="gam-dialog-modal" :class="{ prevent: preventClose, mobile: isMobile, bg: background }">
        <div disabled-v-on-click-outside="closeModal" class="gam-dialog-content" :style="getBgStyle">
          <slot />

          <div v-if="!preventClose && !hideBack" class="back-button" @click="handleClickClose">
            <GamIcon :size="GamIconSize.X_LARGE" :name="GamIconName.X_CLOSE" is-dark />
          </div>
        </div>
      </div>
    </transition>
  </dialog>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useAppStore } from '@/stores/app.store';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { DialogType } from '@/views/composables/models/dialog.interface';
import { computed, ref, type StyleValue } from 'vue';
import GamIcon from '../GamIcon.vue';

const props = defineProps<DialogType>();
const emits = defineEmits([GamComponentsEmits.CLOSE]);
const dialog = ref<HTMLDialogElement>();
const visible = ref(false);
const appStore = useAppStore();

const showModal = () => {
  dialog.value?.showModal();

  if (dialog.value) {
    dialog.value?.addEventListener('cancel', (event) => {
      if (props.preventClose) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }
  appStore.setDialogOpened(true);
  visible.value = true;
};

const closeModal = (): void => {
  if (!props.preventClose) {
    dialog.value?.close();
  }
};

const hideDialog = (): void => {
  appStore.setDialogOpened(false);
  visible.value = false;
  emits(GamComponentsEmits.CLOSE);
};

const handleClickClose = () => {
  hideDialog();
  closeModal();
};

const getBgStyle = computed((): StyleValue => {
  const bg = props.background ? `/images/dialogs/${props.background}` : undefined;
  return {
    background: bg ? `var(--color-dark-700) url(${bg})` : 'var(--color-dark-700)',
  };
});

defineExpose({
  show: showModal,
  close: closeModal,
  visible,
});
</script>

<style lang="scss">
@use '@/ui/css/partial';

.gam-dialog {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: none;
  border: 0;
  margin: 0 auto;
  transition: visibility var(--transition-loader);
  color: var(--color-white-100);

  &::backdrop {
    background: rgba(24, 23, 22, 0.7);
  }

  .gam-dialog-modal {
    display: flex;
    position: relative;
    width: auto;
    max-width: 500px;
    min-height: 100%;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    &.mobile {
      max-width: 88vw;
    }

    &.bg {
      .gam-dialog-content {
        min-height: 600px;
      }
    }

    .gam-dialog-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: fit-content;
      pointer-events: auto;
      align-items: center;
      justify-content: flex-end;
      flex-shrink: 0;
      padding: var(--spacing-menu) var(--spacing-menu) var(--spacing-xxl) var(--spacing-menu);
      border-radius: var(--radius-large);
      background: var(--color-dark-700);
      background-clip: padding-box;
      background-size: cover !important;
      box-shadow: var(--shadow-backdrop-panel-downer) var(--shadow-pink-15);
      z-index: 100;
      @extend .gam-special-border !optional;

      &:before {
        padding: 1px 0 0;
        background: var(--color-linear-gradient-70);
      }

      .back-button {
        position: absolute;
        bottom: -16px;
        background-color: var(--color-white-100);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        cursor: pointer;

        &:hover {
          border: 2px solid var(--color-pink);
        }
      }
    }

    &.prevent {
      .gam-dialog-content {
        padding: var(--spacing-menu);
      }
    }
  }
}
</style>
