import type { BaseClubDto, ClubFormData, DetailClubDto } from '@/core/data/club/club.interface';
import type { ClubRepository } from '@/core/data/club/club.repository';
import type { GeoLocation } from '@/core/data/location/location.interface';
import FormHelper from '@/core/helpers/form.helper';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import type { ClubForm } from '@/views/composables/models/form.interface';

export class ClubService {
  private readonly repository: ClubRepository;

  constructor(repository: ClubRepository) {
    this.repository = repository;
  }

  async getClubs(geo?: GeoLocation, afterCursor?: string | null): Promise<Result<BaseClubDto[]>> {
    try {
      const response = await this.repository.getClubs(geo, afterCursor);
      return response?.data ? { data: response.data, cursor: response.cursor } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async followClub(id: string): Promise<Result<void>> {
    try {
      const result = await this.repository.followClub(id);
      return result?.data;
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async unfollowClub(id: string): Promise<Result<void>> {
    try {
      await this.repository.unfollowClub(id);
      return { data: undefined };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getClub(id: string): Promise<Result<DetailClubDto>> {
    try {
      const response = await this.repository.getClub(id);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getClubByUsername(username: string): Promise<Result<DetailClubDto>> {
    try {
      const response = await this.repository.getClubByUsername(username);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async addNewClub(formData: ClubForm): Promise<Result<BaseClubDto>> {
    try {
      const data: ClubFormData = {
        name: formData.name,
        username: formData.username,
        clubType: formData.clubType,
        description: formData.description,
        images: formData.photos.map((photo) => FormHelper.parseImageFile(photo)),
        profileImage: FormHelper.parseImageFile(formData.profileImage),
      };
      const response = await this.repository.addNewClub(data);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async updateClub(id: string, formData: ClubForm): Promise<Result<BaseClubDto>> {
    try {
      const data: ClubFormData = {
        name: formData.name,
        username: formData.username,
        clubType: formData.clubType,
        description: formData.description,
        images: formData.photos.map((photo) => FormHelper.parseImageFile(photo)),
        profileImage: FormHelper.parseImageFile(formData.profileImage),
      };
      const response = await this.repository.updateClub(id, data);
      return response?.data ? { data: response.data } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async deleteClub(id: string): Promise<Result<BaseClubDto> | null> {
    try {
      const response = await this.repository.deleteClub(id);
      return response?.data ? { data: response.data } : null;
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async addLocation(id: string, locationId: string): Promise<Result<BaseClubDto> | undefined> {
    try {
      const response = await this.repository.addLocation(id, locationId);
      return response;
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async removeLocation(id: string, locationId: string): Promise<Result<BaseClubDto> | undefined> {
    try {
      const response = await this.repository.removeLocation(id, locationId);
      return response;
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async addCurator(id: string, username: string): Promise<Result<BaseClubDto> | undefined> {
    try {
      const response = await this.repository.addCurator(id, username);
      return response;
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async removeCurator(id: string, username: string): Promise<Result<BaseClubDto> | undefined> {
    try {
      const response = await this.repository.removeCurator(id, username);
      return response;
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }
}
