import type { BaseGambitDto, GambitFormData, GambitRecurrenceOptions } from '@/core/data/gambit/gambit.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import type { GambitApi } from '@/core/network/api/gambit/gambit.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HandleApiError, HttpError } from '@/core/network/http/httpError';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { GambitDetailDto } from '@/views/composables/models/components/GamGambit';
import type { GeoLocation } from '../location/location.interface';

const logger = createLogger('GambitRepository');

export class GambitRepository {
  private readonly httpAPI: GambitApi;

  constructor(httpAPI: GambitApi) {
    this.httpAPI = httpAPI;
  }

  async getGambits(listId: GamListId, location: GeoLocation): Promise<GamResponse<BaseGambitDto[]> | null> {
    try {
      const response = await this.httpAPI.getGambits(listId, location);
      return response.data?.data ? { data: response.data.data, cursor: response.data.cursor } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getGambit(id: string): Promise<GamResponse<GambitDetailDto> | null> {
    try {
      const response = await this.httpAPI.getGambit(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async addNewGambit(data: GambitFormData): Promise<GamResponse<BaseGambitDto> | null> {
    try {
      const response = await this.httpAPI.addGambit(data);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async updateGambit(
    id: string,
    data: GambitFormData,
    options?: GambitRecurrenceOptions,
  ): Promise<GamResponse<BaseGambitDto | { status: number }> | null> {
    try {
      const response = await this.httpAPI.updateGambit(id, data, options);
      return response.status === 200 ? response.data || { data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async deleteGambit(id: string, options?: GambitRecurrenceOptions): Promise<GamResponse<BaseGambitDto> | null> {
    try {
      const response = await this.httpAPI.deleteGambit(id, options);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }
}
