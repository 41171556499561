import Bowser from 'bowser'; // TypeScript

export function detectSafari() {
  try {
    const clientInfo = Bowser.parse(window.navigator.userAgent);
    const { browser } = clientInfo || {};
    const { name } = browser || '';

    if (name.toLowerCase() === 'safari') {
      const bodyEl = document.querySelector('body');
      bodyEl.classList.add('safari-quirks');
    }
  } catch (e) {
    throw new Error(`Couldnt detect client browser: ${e}`);
  }
}

export function detectTouchDevice() {
  try {
    const clientInfo = Bowser.parse(window.navigator.userAgent);

    const { platform } = clientInfo || {};
    const { type } = platform || {};

    const isTouchDevice = type === 'mobile' || type === 'tablet';

    if (isTouchDevice) {
      const bodyEl = document.querySelector('body');
      bodyEl.classList.add('touch-device');
    }

    return isTouchDevice;
  } catch (e) {
    throw new Error(`Couldnt detect client browser: ${e}`);
  }
}
