import throttle from 'lodash/throttle';
import { ref } from 'vue';

const queries = {
  sm: '(max-width: 640px)',
  md: '(min-width: 640px and max-width: 1024px)',
  lg: '(min-width: 1024px and max-width: 1280px)',
  xl: '(min-width: 1280px)',
};

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl';

export const isMobile = ref<boolean>();
export const isTablet = ref<boolean>();
export const isLaptop = ref<boolean>();
export const isDesktop = ref<boolean>();
export const breakpoint = ref<Breakpoint>();

function determineMedia() {
  isMobile.value = window.matchMedia(queries.sm).matches;
  isTablet.value = window.matchMedia(queries.sm).matches;
  isLaptop.value = window.matchMedia(queries.sm).matches;
  isDesktop.value = window.matchMedia(queries.sm).matches;

  breakpoint.value = isMobile.value ? 'sm' : isTablet.value ? 'md' : isLaptop.value ? 'lg' : 'xl';
}

determineMedia();
window.addEventListener('resize', throttle(determineMedia, 200));
