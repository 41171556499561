<template>
  <div class="qqe-dialog" :class="{ mobile: isMobile }">
    <div class="title">
      {{ localize('dialog.cookies.title') }}
    </div>
    <div class="info-wrapper">
      <gam-switch v-bind="getFunctionalCheckbox()" />
      <gam-switch v-bind="getPerformanceCheckbox()" v-model:checked="selectedPerformance" />
    </div>
    <div class="button-group">
      <gam-button v-bind="getActionButton" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamSwitch from '@/views/components/GamSwitch.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamSwitchPosition, GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import type { CookieDialogType } from '@/views/composables/models/dialog.interface';
import { computed, ref } from 'vue';

const props = defineProps<CookieDialogType>();

const selectedPerformance = ref<boolean>(false);

const getActionButton = computed((): GamButtonType => {
  return {
    label: 'dialog.cookies.button.label',
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.LARGE,
    isFullSize: true,
    center: true,
    onClick: () => {
      props.callback(selectedPerformance.value);
    },
  };
});

const getFunctionalCheckbox = (): GamSwitchType => {
  return {
    label: localize('dialog.cookies.options.functional.label'),
    name: '',
    supportText: localize('dialog.cookies.options.functional.message'),
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.LEFT,
    checked: true,
    disabled: true,
  };
};

const getPerformanceCheckbox = (): GamSwitchType => {
  return {
    label: localize('dialog.cookies.options.performance.label'),
    name: '',
    supportText: localize('dialog.cookies.options.performance.message'),
    type: GamSwitchValueType.SWITCH,
    position: GamSwitchPosition.LEFT,
  };
};
</script>

<style lang="scss">
.qqe-dialog {
  display: flex;
  position: relative;
  width: calc(335px - 2 * var(--spacing-menu));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);

  &.mobile {
    width: 100%;
    min-width: 0;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
    line-height: 110%;
    text-align: center;
    align-self: stretch;
    padding: var(--spacing-menu) 0;
  }

  .info-wrapper {
    display: flex;
    padding-bottom: var(--spacing-menu);
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: var(--spacing-menu);
    align-self: stretch;
  }

  .button-group {
    display: flex;
    padding: var(--spacing-l) 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
  }
}
</style>
