import type { UIMainMenuItem } from '@/core/data/config/uiConfig.interface';
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import NavigationHelper from '@/core/helpers/navigation.helper';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import type { GamNavItemType } from '@/views/composables/models/components/GamNav';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNavigationStore = defineStore(GamStoreId.NAVIGATION, () => {
  const isNavVisible = ref<boolean>(false);
  const maxNavItems = ref<number>(5);
  const authStore = useAuthStore();
  const isCollapsed = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  const isMoreOpened = ref<boolean>(false);
  const navItems = ref<GamNavItemType[] | null>(null);

  const isSwipeAware = ref<boolean>(false);
  const setSwipeAware = (state: boolean) => {
    isSwipeAware.value = state;
  };

  const isAddingEntry = ref<boolean>(false);
  const setAddingEntry = (state: boolean) => {
    isAddingEntry.value = state;
  };

  const isEditingEntry = ref<boolean>(false);
  const setEditingEntry = (state: boolean) => {
    isEditingEntry.value = state;
  };

  const setNavVisible = (value: boolean): void => {
    isNavVisible.value = value;
  };

  const setMoreOpened = (value: boolean): void => {
    isMoreOpened.value = value;
  };

  const setNavItems = (mainMenuItems: UIMainMenuItem[] | null) => {
    navItems.value = mainMenuItems?.filter((item) => item.isActive) || [];
  };

  const isShowMoreBubble = (): boolean => {
    return !!getNavItemsMore()?.some((item) => item.messages?.count);
  };

  const setMenuMessageCount = (name: RouterNameType, count: number): void => {
    const navItem = navItems.value?.find((item) => item.name === name);
    if (navItem) navItem.messages = { count };
  };

  const setLoading = (value: boolean) => {
    isLoading.value = value;
  };

  const getNavItems = (): GamNavItemType[] => {
    if (!navItems.value?.length) {
      return [];
    }

    let filteredNavItems = navItems.value;
    if (isMobile.value) {
      if (navItems.value.length > maxNavItems.value) {
        const items: GamNavItemType[] = navItems.value.slice(0, maxNavItems.value - 1);
        const moreItem = NavigationHelper.getMoreNavItem(maxNavItems.value, isShowMoreBubble());
        items.splice(maxNavItems.value - 1, 0, moreItem);
        filteredNavItems = items;
      } else {
        filteredNavItems = navItems.value;
      }
    } else {
      filteredNavItems = navItems.value.filter((item) => !item.showBottom);
    }

    return filteredNavItems.filter((item) => authStore.isAdmin() || !item.isRestrictedToAdmins);
  };
  const getNavItemsBottom = (): GamNavItemType[] => {
    if (navItems.value?.length && !isMobile.value) {
      return navItems.value.filter((item) => item.showBottom && (authStore.isAdmin() || !item.isRestrictedToAdmins));
    }
    return [];
  };
  const getNavItemsMore = (): GamNavItemType[] => {
    if (navItems.value && navItems.value.length > maxNavItems.value && isMobile.value) {
      return navItems.value.slice(maxNavItems.value - 1);
    }
    return [];
  };

  return {
    navItems,
    isMobile,
    isCollapsed,
    isLoading,
    isNavVisible,
    isMoreOpened,
    isAddingEntry,
    isEditingEntry,
    isSwipeAware,
    setSwipeAware,
    setAddingEntry,
    setEditingEntry,
    setNavVisible,
    setMoreOpened,
    setLoading,
    setNavItems,
    setMenuMessageCount,
    getNavItems,
    getNavItemsBottom,
    getNavItemsMore,
  };
});
