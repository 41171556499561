import type {
  AuthUserDto,
  AuthValidateOtpPayload,
  JWT,
  OtpMailData,
  RegisterFormData,
  UserFormData,
} from '@/core/data/auth/auth.interface';
import type { LoginMethodType } from '@/core/data/auth/auth.type';
import type { CodeResponse } from '@/core/data/auth/google/oauth2';
import type { JwtRepository } from '@/core/data/auth/jwt.repository';
import { StorageKeyType, type StorageRepositoryInterface } from '@/core/data/storage/storage.interface';
import type { UserRepository } from '@/core/data/user/user.repository';
import { createLogger } from '@/core/helpers/logger.helper';
import type { AuthApi } from '@/core/network/api/auth.api';
import { HandleApiError } from '@/core/network/http/httpError';

const logger = createLogger('AuthRepository');

export class AuthRepository {
  private readonly authApi: AuthApi;
  private readonly jwtRepository: JwtRepository;
  private readonly userRepository: UserRepository;
  private readonly storage: StorageRepositoryInterface;
  private readonly session: StorageRepositoryInterface;

  constructor(
    jwtRepository: JwtRepository,
    userRepository: UserRepository,
    AuthApi: AuthApi,
    storage: StorageRepositoryInterface,
    session: StorageRepositoryInterface,
  ) {
    this.authApi = AuthApi;
    this.jwtRepository = jwtRepository;
    this.userRepository = userRepository;
    this.storage = storage;
    this.session = session;
  }

  async authWithMail(email: string): Promise<boolean> {
    try {
      const response = await this.authApi.authWithEmail(email);
      if (response?.status === 201) {
        this.session.set<OtpMailData>(StorageKeyType.OtpSend, {
          email,
          sendTime: Date.now(),
        });
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  private async setAuthData(token: JWT, statusCode: number): Promise<AuthUserDto | null> {
    this.jwtRepository.setJWToken(token);
    if (statusCode >= 200 && statusCode < 300) {
      this.storage.remove(StorageKeyType.OtpSend);
      await this.userRepository.getUserInfo(true);
      return this.jwtRepository.getUser();
    }
    return null;
  }

  async authValidateOtp(payload: AuthValidateOtpPayload): Promise<AuthUserDto | null> {
    try {
      const response = await this.authApi.authValidateOtp(payload);
      return response?.data.data ? await this.setAuthData(response.data.data, response.status) : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async authGoogle(code: CodeResponse): Promise<AuthUserDto | null> {
    try {
      const response = await this.authApi.authWithGoogle(code);
      return response?.data.data ? await this.setAuthData(response.data.data, response.status) : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async getToken(code: string): Promise<AuthUserDto | null> {
    try {
      const response = await this.authApi.authGetToken(code);
      return response?.data.data ? await this.setAuthData(response.data.data, response.status) : null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async registerUser(formData: UserFormData): Promise<AuthUserDto | null> {
    try {
      const response = await this.authApi.registerUser(formData);

      if (response?.data.data) {
        this.removeOnboardingData();
        return await this.setAuthData(response.data.data, response.status);
      }
      return null;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  async deleteUser(): Promise<boolean> {
    try {
      const response = await this.authApi.deleteUser();
      return response.status >= 200 && response.status < 300;
    } catch (e) {
      return HandleApiError(e, logger);
    }
  }

  isAuthenticated(): boolean {
    return !!this.jwtRepository.getJWToken();
  }

  getUser(): AuthUserDto | null {
    return this.jwtRepository.getUser();
  }

  getOtpMailData(): OtpMailData | null {
    return this.session.get<OtpMailData>(StorageKeyType.OtpSend);
  }

  saveTempRegisterData(data: RegisterFormData): void {
    this.session.set<RegisterFormData>(StorageKeyType.Register, data);
  }

  getRegisterData(): RegisterFormData | null {
    return this.session.get<RegisterFormData>(StorageKeyType.Register);
  }

  removeOnboardingData(): void {
    this.session.remove(StorageKeyType.Register);
    this.session.remove(StorageKeyType.OtpSend);
  }

  setConnecting(type: LoginMethodType): void {
    this.session.set<LoginMethodType>(StorageKeyType.AuthConnecting, type);
  }

  getConnecting(): LoginMethodType | null {
    return this.session.get<LoginMethodType>(StorageKeyType.AuthConnecting);
  }

  removeConnecting(): void {
    this.session.remove(StorageKeyType.AuthConnecting);
  }

  async logout(): Promise<void> {
    await this.jwtRepository.logout();
  }
}
