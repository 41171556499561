<template>
  <div class="gam-switch">
    <label :class="['gam-switch-container', { active: isChecked }, position, type]">
      <input
        v-if="type !== GamSwitchValueType.CHECKED"
        v-bind="$attrs"
        :value="value"
        :name="name"
        :type="getInputType"
        :checked="isChecked"
        :disabled="disabled"
        @change="updateCheckStatus"
      />

      <span class="checkmark">
        <gam-icon
          v-if="type === GamSwitchValueType.CHECKBOX || type === GamSwitchValueType.CHECKED"
          class="checkmark-icon"
          :name="GamIconName.CHECK"
          :size="getCheckIconSize()"
        />
      </span>

      <span v-if="label" class="gam-switch-content">
        <span class="gam-switch-label">{{ localize(label) }}</span>

        <span v-if="supportText" class="gam-switch-support-text">
          {{ localize(supportText) }}
        </span>
      </span>
    </label>
  </div>
</template>
<script setup lang="ts">
import { localize } from '@/core/gambits';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamSwitchValueType } from '@/views/composables/constants/components/gamSwitch.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamSwitchType } from '@/views/composables/models/components/GamSwitch';
import { computed, ref, watch } from 'vue';

const props = defineProps<GamSwitchType>();

const emit = defineEmits([
  GamComponentsEmits.UPDATE_VALUE,
  GamComponentsEmits.UPDATE_CHECKED,
  GamComponentsEmits.UPDATE_CHECKBOX,
]);

const checked = ref<boolean>(props.checked);
const checkValues = ref<string[]>();

const updateCheckStatus = (value: Event) => {
  if (props.disabled) return;
  const target = value?.target as HTMLInputElement;

  if (props.type === GamSwitchValueType.RADIO) {
    emit(GamComponentsEmits.UPDATE_VALUE, target?.value);
  } else if (props.type === GamSwitchValueType.CHECKBOX) {
    checked.value = target?.checked;
    emit(GamComponentsEmits.UPDATE_CHECKBOX, {
      value: target?.value,
      checked: target?.checked,
    });
  } else {
    checked.value = target?.checked;
    emit(GamComponentsEmits.UPDATE_CHECKED, checked.value);
  }
};

const getCheckIconSize = (): GamIconSize => {
  if (props.type === GamSwitchValueType.CHECKED) {
    return GamIconSize.X_LARGE;
  } else {
    return GamIconSize.NORMAL;
  }
};

const getInputType = computed((): string => {
  if (props.type === GamSwitchValueType.RADIO) {
    return GamSwitchValueType.RADIO;
  } else {
    return GamSwitchValueType.CHECKBOX;
  }
});

const isChecked = computed((): boolean => {
  switch (props.type) {
    case GamSwitchValueType.RADIO:
      return props.modelValue === props.value;
    case GamSwitchValueType.CHECKBOX:
    case GamSwitchValueType.SWITCH:
      return checked.value;
    default:
      return true;
  }
});

watch(
  () => props.checked,
  (value?: boolean) => {
    checked.value = value || false;
  },
);

defineExpose({
  checked,
  checkValues,
});
</script>
<style scoped lang="scss">
@use '@/ui/css/partial';

$switch-size: 16px;
$radio-size: 8px;

.gam-switch {
  width: auto;
  display: flex;
  overflow: hidden;

  .gam-switch-container {
    display: flex;
    align-items: flex-start;
    gap: var(--spacing-md);
    cursor: pointer;
    align-self: stretch;

    input {
      position: absolute;
      width: 0;
      height: 0;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;

      &:checked ~ .checkmark {
        &:before {
          opacity: 1 !important;
        }

        &:after {
          opacity: 1 !important;
        }

        @extend .gam-special-border !optional;

        &:before {
          opacity: 0.7;
          padding: 1px;
          background: var(--color-linear-gradient);
        }
      }
    }

    .checkmark {
      display: flex;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      border-radius: inherit;
      transition: opacity var(--transition);

      @extend .gam-special-border !optional;

      &:before {
        opacity: 0.7;
        padding: 1px;
        background: var(--color-white-10);
      }

      &:hover {
        &:before {
          background: var(--color-white-70);
        }

        &:after {
          background: var(--color-white-100) !important;
        }
      }
    }

    .gam-switch-content {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      gap: var(--spacing-md);
      min-width: 0;

      .gam-switch-label {
        max-width: inherit;
        padding: 4px 0;
        font-size: 16px;
        font-weight: 300;
        color: var(--color-white-70);
        transition: color var(--transition);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .gam-switch-support-text {
        font-size: 14px;
        font-weight: 400;
        color: var(--color-white-30);
        line-height: 110%;
      }
    }

    &.switch {
      input {
        &:checked ~ .checkmark {
          &:after {
            background-color: var(--color-white-100);
            transform: translateX(18px);
          }
        }
      }

      .checkmark {
        width: 42px;
        height: 24px;
        border-radius: 14px;

        &:after {
          position: absolute;
          content: '';
          height: $switch-size;
          width: $switch-size;
          border-radius: 50%;
          left: 4px;
          top: 4px;
          background-color: var(--color-white-30);
          transition: all var(--transition);
        }
      }
    }

    &.checkbox {
      input {
        &:checked ~ .checkmark {
          .checkmark-icon {
            opacity: 1;
          }
        }
      }

      .checkmark {
        width: 24px;
        height: 24px;
        border-radius: 4px;

        .checkmark-icon {
          position: absolute;
          left: 4px;
          top: 4px;
          opacity: 0;
          transition: opacity var(--transition);
        }
      }
    }

    &.radio {
      .checkmark {
        width: 24px;
        height: 24px;
        border-radius: 50%;

        &:after {
          position: absolute;
          content: '';
          height: $radio-size;
          width: $radio-size;
          border-radius: 50%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: var(--color-white-100);
          transition: opacity var(--transition);
          opacity: 0;
        }
      }
    }

    &.checked {
      .checkmark {
        padding: 0;

        &:before {
          display: none;
        }
      }
    }

    &.active {
      .gam-switch-content {
        .gam-switch-label {
          color: var(--color-white-100);
        }
      }
    }

    &.right {
      flex-direction: row-reverse;
      width: 100%;
      justify-content: space-between;
    }
  }
}
</style>
