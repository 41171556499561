import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAppStore = defineStore(GamStoreId.APP, () => {
  const dialogOpened = ref<boolean>(false);
  const drawerOpened = ref<boolean>(false);
  const imageExpanded = ref<boolean>(false);

  const setDialogOpened = (value: boolean) => {
    dialogOpened.value = value;
  };

  const setDrawerOpened = (value: boolean) => {
    drawerOpened.value = value;
  };

  const setImageExpanded = (value: boolean) => {
    imageExpanded.value = value;
  };

  return {
    dialogOpened,
    drawerOpened,
    imageExpanded,
    setDialogOpened,
    setDrawerOpened,
    setImageExpanded,
  };
});
