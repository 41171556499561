import type { LocationGambitDto } from '@/core/data/gambit/gambit.interface';
import type { AuthorDto, GeoLocation, SearchLocationDto } from '@/core/data/location/location.interface';
import { LocationType, MapLocationSource, MapLocationType } from '@/core/data/location/location.type';
import { gambits } from '@/core/gambits';
import { useMapStore } from '@/stores/map.store';
import { useTableStore } from '@/stores/table.store';
import { GamChipSize, GamChipVariant } from '@/views/composables/constants/components/gamChip.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamMapPinLocation, GamMapPinVariant } from '@/views/composables/constants/components/gamMap.constants';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamDropdownItem } from '@/views/composables/models/components/GamDropdown';
import { isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { CustomMarker } from 'vue3-google-map';

export default class LocationHelper {
  static getLocationIcon(location: GamMapPinLocation): GamIconName {
    switch (location) {
      case GamMapPinLocation.CAFE:
        return GamIconName.COFFEE;
      case GamMapPinLocation.STREET:
        return GamIconName.STREET_LOCATION;
      case GamMapPinLocation.GIANT:
        return GamIconName.GIANT_CHESS;
      case GamMapPinLocation.CLUB:
        return GamIconName.CLUB;
      case GamMapPinLocation.FAVORITE:
        return GamIconName.HEART;
      case GamMapPinLocation.EVENT:
        return GamIconName.GAMBIT;
      case GamMapPinLocation.HOME:
        return GamIconName.HOUSE;
      default:
        return GamIconName.PAWN;
    }
  }

  static getLocationTypeIcon(types: LocationType[], isPrivate?: boolean, isFavorite?: boolean): GamIconName {
    if (isPrivate) {
      return GamIconName.HOUSE;
    } else if (isFavorite) {
      return GamIconName.HEART;
    } else if (types.length) {
      switch (types[0]) {
        case LocationType.CAFFE_CHESS:
          return GamIconName.COFFEE;
        case LocationType.CHESS_TABLE:
          return GamIconName.STREET_LOCATION;
        case LocationType.GIANT_CHESS:
          return GamIconName.GIANT_CHESS;
        case LocationType.CLUB:
          return GamIconName.CLUB;
        case LocationType.VENUE:
          return GamIconName.VENUE;
        case LocationType.HOME:
          return GamIconName.HOUSE;
        case LocationType.FAVORITE:
          return GamIconName.HEART;
        case LocationType.GAMBIT:
          return GamIconName.GAMBIT;
        default:
          return GamIconName.PAWN;
      }
    }
    return GamIconName.PAWN;
  }

  static getPinType(type: MapLocationType, gambit?: LocationGambitDto | null): string {
    if (type === MapLocationType.LOCATION) {
      return gambit ? GamMapPinVariant.HAS_GAMBIT : GamMapPinVariant.DEFAULT;
    }
    return GamMapPinVariant.GAMBIT;
  }

  static setCoordinate(value?: number) {
    if (!value || value === Infinity) {
      return 0;
    } else {
      return value;
    }
  }

  static getMyLocationOption = (): GamDropdownItem => {
    const { userLocation } = storeToRefs(useMapStore());

    return {
      id: 'current',
      value: LocationType.MY_LOCATION,
      label: 'app.component.search.location.current',
      icon: GamIconName.TARGET,
      isFullSize: true,
      coordinates: {
        lat: this.setCoordinate(userLocation.value?.location.lat || 0),
        lng: this.setCoordinate(userLocation.value?.location.lng || 0),
      },
    };
  };

  static getBaseLocationTag(label: string, isSmall: boolean, icon?: GamIconName): GamChipType {
    return {
      label,
      size: isSmall ? GamChipSize.SMALL : GamChipSize.NORMAL,
      variant: GamChipVariant.DEFAULT,
      isOutline: false,
      leftIcon: icon,
    };
  }

  static isLocationDenied(code?: number): boolean {
    return code === GeolocationPositionError.PERMISSION_DENIED;
  }

  static isPositionInvalid(code?: number): boolean {
    return code === GeolocationPositionError.POSITION_UNAVAILABLE;
  }
  static isTimeout(code?: number): boolean {
    return code === GeolocationPositionError.TIMEOUT;
  }

  static mapMarkers(pins?: InstanceType<typeof CustomMarker>[]): any[] {
    if (!pins) return [];
    return pins.map((pin) => {
      return pin.customMarker;
    });
  }

  static redirectToGoogleMaps(loc: GeoLocation): void {
    const url = `https://www.google.com/maps/?q=${loc.lat},${loc.lng}`;
    window.open(url, '_blank');
  }

  static mapSearchLocations(data?: SearchLocationDto[]): GamDropdownItem[] {
    if (!data) return [];
    return data.map((location: SearchLocationDto) => {
      return {
        id: location.id,
        accuracy: location.accuracy || 'unknown',
        label: location.name || location.address,
        subLabel: location.address,
        value: location.id || location.name || location.address,
        nearbyPlaceAddress: location.nearbyPlaceAddress,
        icon:
          location.source === MapLocationSource.INTERNAL
            ? this.getLocationTypeIcon(location.locationTypes || [LocationType.CHESS_FRIENDLY])
            : GamIconName.LOCATION_EMPTY,
        coordinates: location.coordinates,
        source: location.source,
        isFullSize: true,
        chessLocation: false,
      } as GamDropdownItem;
    });
  }

  static isAddMode(): boolean {
    const mapStore = useMapStore();
    // TODO: Infer add mode from URL
    // Makle sure there aren't other places that rely on this state variable
    const { addMode } = storeToRefs(mapStore);

    const tableStore = useTableStore<GamDropdownItem>(GamListId.ADD_LOCATION)();
    const { selectedItem } = storeToRefs(tableStore);

    return !(!addMode.value && !selectedItem.value);
  }

  static selectedLocation(): GamDropdownItem | null {
    const tableStore = useTableStore<GamDropdownItem>(GamListId.LOCATIONS)();
    const { selectedItem } = storeToRefs(tableStore);

    return selectedItem.value;
  }

  static selectedAddLocation(): GamDropdownItem | null {
    const addTableStore = useTableStore<GamDropdownItem>(GamListId.ADD_LOCATION)();
    const { selectedItem } = storeToRefs(addTableStore);

    return selectedItem.value;
  }

  static selectedSearchLocation(): GamDropdownItem | null {
    const searchTableStore = useTableStore<GamDropdownItem>(GamListId.LOCATION_SEARCH)();
    const { selectedItem } = storeToRefs(searchTableStore);
    return selectedItem.value;
  }

  static isValidDDFormat(coordinateString: string): boolean {
    const [latitude, longitude] = coordinateString.split(',');
    const lat = parseFloat(latitude?.trim());
    const lon = parseFloat(longitude?.trim());
    return !isNaN(lat) && !isNaN(lon) && lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
  }

  static parseSearchToCoordinates(query?: string): GeoLocation | undefined {
    if (query && this.isValidDDFormat(query)) {
      const splitText = query.split(',');
      const coordArray = splitText.map((entry) => Number(entry));

      return {
        lat: coordArray[1],
        lng: coordArray[0],
      };
    }
    return undefined;
  }

  static getAddress(selectedItem: GamDropdownItem | null): string | undefined {
    if (selectedItem?.subLabel && !this.isValidDDFormat(selectedItem.label)) {
      return `${selectedItem.label}, ${selectedItem.subLabel}`;
    }
    return selectedItem?.label;
  }

  static async authAuthor(): Promise<AuthorDto> {
    const user = await gambits.userService.getUserInfo();
    return {
      id: user.data?.id || '',
      username: user.data?.username || '',
      country: user.data?.country,
      emoji: user.data?.emoji,
      profileImageBlobHash: user.data?.profileImageBlobHash,
    };
  }

  private static roundLocation(loc: GeoLocation): GeoLocation {
    return {
      lat: parseFloat(loc.lat.toFixed(2)),
      lng: parseFloat(loc.lng.toFixed(2)),
    };
  }

  static shouldUpdateUserLocation(oldLoc?: GeoLocation, newLoc?: GeoLocation): boolean {
    if (!oldLoc || !newLoc) return true;
    return !isEqual(this.roundLocation(oldLoc), this.roundLocation(newLoc));
  }
}
