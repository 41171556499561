<template>
  <div :class="['gam-nav-item-link', getClasses, variant]" @click="onClick">
    <div v-if="icon" class="nav-icon">
      <gam-icon v-bind="getIcon" />
    </div>
    <div class="nav-label">
      {{ localize(displayName) }}
    </div>
    <div v-if="statusChips?.length" class="status-chips">
      <gam-chip v-for="(chip, index) in statusChips" :key="index" v-bind="getStatusChip(chip)" />
    </div>
    <div v-if="button">
      <gam-button v-bind="getButton" />
    </div>
    <div v-if="showArrow" class="nav-arrow">
      <gam-button v-bind="getArrowButton" />
    </div>
    <transition mode="out-in">
      <div v-if="getMessageChip" class="nav-messages">
        <gam-chip v-bind="getMessageChip" />
      </div>
    </transition>
    <transition mode="out-in">
      <div v-if="props.messages?.count && props.showMore" class="show-more-info" />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { localize } from '@/core/gambits';
import NavigationHelper from '@/core/helpers/navigation.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamChip from '@/views/components/GamChip.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamChipSize, GamChipVariant } from '@/views/composables/constants/components/gamChip.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import { GamNavSize } from '@/views/composables/constants/components/gamNav.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import type { GamNavItemLinkType } from '@/views/composables/models/components/GamNav';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps<GamNavItemLinkType>();

const router = useRouter();

const showMessageChip = (): boolean => {
  return (props.isMobile && props.isMore) || !props.isMobile;
};

const isNavItemActive = (): boolean => {
  const currentRoute = router?.currentRoute.value.name;
  const isMoreActive = NavigationHelper.isMoreActive(currentRoute) && props.showMore;
  const isCurrentRoute = currentRoute === props.link;

  return props.isActive || isMoreActive || isCurrentRoute;
};

const getStatusChip = (chip: GamChipType): GamChipType => {
  return {
    ...chip,
    size: GamChipSize.NORMAL,
  };
};

const onClick = (e: MouseEvent) => {
  props.onClick?.(e);
};

const getClasses = computed(() => ({
  mobile: props.isMobile,
  'icon-only': props.isIconOnly && !props.isMobile,
  'is-more': props.isMore,
  'is-active': isNavItemActive(),
  'show-more': props.showMore,
  small: props.size === GamNavSize.SMALL,
}));

const getMessageChip = computed((): GamChipType | undefined => {
  if (props.messages?.count && showMessageChip()) {
    return {
      label: props.messages.count.toString(),
      variant: GamChipVariant.PRIMARY,
      size: GamChipSize.X_SMALL,
      isActive: true,
    };
  }
  return undefined;
});

const getIcon = computed((): GamIconType => {
  const isLargeIcon: boolean = props.isMobile && !props.isMore;
  const iconName = isNavItemActive() ? props.icon?.split('-empty') : props.icon;

  return {
    name: Array.isArray(iconName) ? (iconName[0] as GamIconName) : props.icon || '',
    size: isLargeIcon ? GamIconSize.X_LARGE : GamIconSize.NORMAL,
  };
});

const getButton = computed((): GamButtonType => {
  return {
    ...props.button,
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.ACTIONS,
  };
});

const getArrowButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.TERTIARY,
    size: GamButtonSize.ACTIONS,
    isIconOnly: true,
    leftIcon: GamIconName.ARROW_RIGHT,
  };
});
</script>

<style scoped lang="scss">
$message-pop-margin: 6px;

.gam-nav-item-link {
  width: inherit;
  position: relative;
  user-select: none;
  min-width: inherit;
  min-height: 68px;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: var(--radius-small);
  border: 1px solid transparent;
  transition:
    width var(--transition),
    opacity var(--transition),
    border var(--transition),
    background-color var(--transition);
  gap: var(--spacing-md);
  cursor: pointer;

  &.small {
    min-height: var(--spacing-l);
  }

  .nav-label {
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    color: var(--color-white-70);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    flex: 1 0 0;
  }

  &.small .nav-label {
    font-size: 13px;
  }

  .nav-messages {
    transition: all var(--transition);
    position: absolute;
    top: var(--spacing-menu);
    right: 26px;
  }

  .nav-arrow {
    user-select: none;
    pointer-events: none;
  }

  .status-chips {
    display: flex;
    gap: var(--spacing-xs);
  }

  &.primary {
    padding: var(--spacing-l) 26px;

    &:hover {
      background-color: var(--color-dark-500);
    }

    &.is-active {
      border-color: var(--color-white-30);
      background-color: var(--color-dark-500);
    }
  }

  &.secondary {
    padding: var(--spacing-l) 0;
    border-radius: 0;
    border-bottom: 1px solid var(--color-white-10);

    &.small {
      padding: var(--spacing-md) 0;
    }

    &:last-child {
      border-bottom: none !important;
    }

    &:hover {
      border-bottom: 1px solid var(--color-white-30);
    }

    &.is-active {
      border-bottom: 1px solid var(--color-white-30);
    }
  }

  &.mobile {
    flex-direction: column;
    background: none !important;
    padding: 0 var(--spacing-xs);
    gap: var(--spacing-xs);
    opacity: 0.4;
    min-width: auto;
    min-height: auto;
    border: 0;

    .nav-label {
      font-size: 10px;
      color: var(--color-white-100);
      font-weight: 400;
    }

    .show-more-info {
      position: absolute;
      background: var(--color-linear-gradient);
      border-radius: 5px;
      top: 0;
      right: 0;
      width: 5px;
      height: 5px;
    }

    &.is-more {
      width: inherit;
      background: var(--color-dark-600) !important;
      padding: var(--spacing-l);
      gap: var(--spacing-md);
      flex-direction: row;
      opacity: 1;

      .nav-label {
        font-size: 16px;
        font-weight: 300;
        color: var(--color-white-70);
      }

      .nav-icon {
        opacity: 0.7;
      }

      .nav-messages {
        transition: all var(--transition);
        position: inherit;
        top: auto;
        right: auto;
      }
    }

    &:hover,
    &.is-active {
      opacity: 1;

      &.is-more {
        .nav-label {
          color: var(--color-white-100);
        }

        .nav-icon {
          opacity: 1;
        }
      }
    }
  }

  &.icon-only {
    width: 68px;

    .nav-icon {
      width: auto;
      align-self: center;
    }

    .nav-messages {
      top: $message-pop-margin;
      right: $message-pop-margin;
    }
  }
}
</style>
