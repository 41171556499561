export enum ReportType {
  USERS = 'users',
  CLUB = 'club',
  GAMBIT = 'gambits',
  LOCATION = 'locations',
  LOCATION_COMMENT = 'locations_comments',
  BLOB = 'blobs',
}

export enum BaseReportReason {
  INAPPROPRIATE = 'inappropriate_content',
  OTHER = 'other',
}

export enum ReportReasonLocation {
  FALSE_COORDINATES = 'false_coordinates',
  LOCATION_DOES_NOT_EXIST = 'location_does_not_exist',
}

export enum ReportReasonPlayer {
  NOT_SHOW_UP = 'not_show_up',
  FALSE_INFO = 'false_info',
}

export enum BlobSource {
  USER_PROFILE = 'user_profile',
  USER_CHESSBOARD = 'user_chessboard',
  LOCATION = 'location',
  COMMENT = 'comment',
  FEED = 'feed',
}

export type AllReportReasons = BaseReportReason | ReportReasonLocation | ReportReasonPlayer;
