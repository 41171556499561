import { createLogger } from '@/core/helpers/logger.helper';
import type { AxiosResponse } from 'axios';
import axios from 'axios';
import type { Translations } from './localization.interface';

const logger = createLogger('LocalizationRepository');

export class LocalizationRepository {
  private translations?: Translations | null;

  async getTranslations(language: string): Promise<AxiosResponse<Translations>> {
    return axios.get(`/translations/${language}.json`);
  }

  translationsForKey(key: string): string | null | undefined {
    return this.translations?.[key];
  }

  async setTranslations(language: string): Promise<void> {
    try {
      const response = await this.getTranslations(language);
      logger.info('Translation has been loaded!');
      this.translations = response?.data || null;
    } catch (e) {
      logger.error(`Could not found translation file for ${language} ${e} language`);
      this.translations = null;
    }
  }
}
