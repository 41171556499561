import { gambits } from '@/core/gambits';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import { formatTimeAgo, useNavigatorLanguage, type UseTimeAgoFormatter } from '@vueuse/core';

type BackButtonOptions = {
  size?: 'small' | 'large';
};

export default class GamHelper {
  static createId = (id: string): string => {
    return `${id}-${Math.random().toString(16).slice(2)}`;
  };

  static getMobileBack = (options?: BackButtonOptions): GamButtonType => {
    return {
      size: GamButtonSize.ACTIONS,
      variant: GamButtonVariant.TERTIARY,
      leftIcon: options?.size === 'large' ? GamIconName.ARROW_LEFT : GamIconName.SMALL_ARROW_LEFT,
    };
  };

  static removeItem<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  static getNavigatorLocale(): string {
    const { language } = useNavigatorLanguage();
    return language.value || 'default';
  }

  static getDayOfDate(dateTime?: string): string {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return date.toLocaleDateString(this.getNavigatorLocale(), { day: '2-digit' });
  }

  static getShortMonthOfDate(dateTime?: string): string {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return date.toLocaleDateString(this.getNavigatorLocale(), { month: 'short' }).toUpperCase();
  }

  static getTimeOfDate(dateTime?: string): string {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return date.toLocaleTimeString(this.getNavigatorLocale(), { hour: '2-digit', minute: '2-digit' });
  }

  static getShortDayOfWeek(dateTime?: string): string {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return date.toLocaleDateString(GamHelper.getNavigatorLocale(), { weekday: 'short' }).toUpperCase();
  }

  static getDate(dateTime?: string): string {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    return date.toLocaleDateString(GamHelper.getNavigatorLocale(), {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    });
  }

  static validateBirthDate = (year: number, month: number, day: number): boolean => {
    const date = new Date(year, month - 1, day);

    if (date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day) {
      return new Date().getFullYear() - year >= 14;
    } else {
      return false;
    }
  };

  static getCurrentUrl(): string {
    const url: string[] = window.location.href.split('/');
    return url[0] + '//' + url[2];
  }

  static getItem<T>(array?: T[], index?: number): T | null {
    if (index?.toString() && array?.[index]) {
      return array[index];
    }
    return null;
  }

  static getLocaleTimeAgo(date: Date): string {
    const locale = gambits.configService.getLanguage();
    const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

    return formatTimeAgo(date, {
      rounding: 'round',
      messages: {
        justNow: gambits.localizationService.localize('app.time.justNow'),
        past: (n: (string | UseTimeAgoFormatter<string>) & (string | UseTimeAgoFormatter<number>)) => n,
        future: (n: (string | UseTimeAgoFormatter<string>) & (string | UseTimeAgoFormatter<number>)) => n,
        ...Object.fromEntries(
          ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'].map((t: any) => [
            t,
            (n: number, past: number) => rtf.format(past ? 0 - n : n, t),
          ]),
        ),
        invalid: '',
      },
    });
  }
}
