import { createLogger } from '@/core/helpers/logger.helper';
import type { ClubApi } from '@/core/network/api/club/club.api';
import type { GamResponse } from '@/core/network/http/httpClient.interface';
import { HttpError } from '@/core/network/http/httpError';
import type { GeoLocation } from '../location/location.interface';
import type { BaseClubDto, ClubFormData, DetailClubDto } from './club.interface';

const logger = createLogger('ClubRepository');

export class ClubRepository {
  private readonly httpAPI: ClubApi;

  constructor(httpAPI: ClubApi) {
    this.httpAPI = httpAPI;
  }

  async getClubs(geo?: GeoLocation, afterCursor?: string | null): Promise<GamResponse<BaseClubDto[]> | null> {
    try {
      const response = await this.httpAPI.getClubs(geo, afterCursor);
      if (response.data?.data) {
        return {
          data: response.data.data,
          cursor: response.data.cursor,
        };
      } else {
        return null;
      }
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async followClub(id: string): Promise<GamResponse<any> | null> {
    try {
      const result = await this.httpAPI.followClub(id);
      return result.data;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async unfollowClub(id: string): Promise<GamResponse<void> | null> {
    try {
      await this.httpAPI.unfollowClub(id);
      return null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getClub(id: string): Promise<GamResponse<DetailClubDto> | null> {
    try {
      const response = await this.httpAPI.getClub(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async getClubByUsername(username: string): Promise<GamResponse<DetailClubDto> | null> {
    try {
      const response = await this.httpAPI.getClubByUsername(username);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async addNewClub(data: ClubFormData): Promise<GamResponse<BaseClubDto> | null> {
    try {
      const response = await this.httpAPI.addClub(data);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async updateClub(id: string, data: ClubFormData): Promise<GamResponse<BaseClubDto> | null> {
    try {
      const response = await this.httpAPI.updateClub(id, data);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async deleteClub(id: string): Promise<GamResponse<BaseClubDto> | null> {
    try {
      const response = await this.httpAPI.deleteClub(id);
      return response.data?.data ? { data: response.data.data } : null;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async addLocation(id: string, locationId: string): Promise<GamResponse<BaseClubDto> | undefined> {
    try {
      await this.httpAPI.addLocation(id, locationId);
      return;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async removeLocation(id: string, locationId: string): Promise<GamResponse<BaseClubDto> | undefined> {
    try {
      await this.httpAPI.removeLocation(id, locationId);
      return;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async addCurator(id: string, username: string): Promise<GamResponse<BaseClubDto> | undefined> {
    try {
      await this.httpAPI.addCurator(id, username);
      return;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }

  async removeCurator(id: string, username: string): Promise<GamResponse<BaseClubDto> | undefined> {
    try {
      await this.httpAPI.removeCurator(id, username);
      return;
    } catch (e) {
      const httpError = e as HttpError;
      logger.error(`${httpError.error.code} => ${httpError.error.message}`);
      throw httpError.error;
    }
  }
}
