<template>
  <div :class="['gam-nav', getNavClasses]">
    <div v-if="isMobile" class="gam-nav-container">
      <div class="gam-nav-items">
        <gam-nav-item
          :name="RouterNameType.LOCATIONS"
          display-name="app.nav.locations"
          :icon="GamIconName.LOCATION_EMPTY"
          :query="{ ...currentLocation, zoom: currentZoom }"
        />
        <gam-nav-item :name="RouterNameType.GAMBITS" display-name="app.nav.gambits" :icon="GamIconName.GAMBIT_EMPTY" />
        <gam-nav-item :name="RouterNameType.CLUBS" display-name="app.nav.clubs" :icon="GamIconName.MUTUAL_EMPTY" />
        <gam-nav-item
          :name="RouterNameType.PROFILE"
          :display-name="userDetails?.username || 'Profile'"
          :icon="GamIconName.PROFILE_EMPTY"
        />
      </div>

      <div v-if="!userDetails" class="gam-mobile-footer">
        <a href="/terms-and-conditions">Terms</a>
        <a href="/privacy">Privacy</a>
        <a href="/contact">Contact</a>
        <a href="/imprint">Imprint</a>
      </div>
    </div>

    <div v-if="!isMobile" class="gam-nav-container">
      <div class="gam-logo">
        <img :src="getAppLogo" :alt="localize('app.name.full')" />
      </div>

      <div v-if="navigationStore.getNavItems()?.length" class="gam-nav-items">
        <gam-nav-item
          v-for="(navItem, index) in navigationStore.getNavItems()"
          :key="`nav-i${index}`"
          v-bind="getNavItem(navItem)"
        />
      </div>

      <div class="gam-nav-items-bottom">
        <gam-nav-item
          :name="RouterNameType.PROFILE"
          :display-name="userDetails?.username || 'Login / Signup'"
          :icon="GamIconName.PROFILE_EMPTY"
        />
      </div>

      <gam-nav-footer v-if="showNavFooter" />
    </div>

    <transition name="fade">
      <div v-if="isMoreOpened" disabled-v-on-click-outside="closeMoreMenu" :class="['gam-nav-more-container']">
        <div class="gam-nav-items-more">
          <gam-nav-item
            v-for="(navItem, mIndex) in navItems"
            :key="`nav-im${mIndex}`"
            :is-more="true"
            v-bind="getNavItem(navItem)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { RouterNameType } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import { useAuthStore } from '@/stores/auth.store';
import { useMapStore } from '@/stores/map.store';
import { useNavigationStore } from '@/stores/navigation.store';
import GamNavFooter from '@/views/components/gam-nav/GamNavFooter.vue';
import GamNavItem from '@/views/components/gam-nav/GamNavItem.vue';
import { GamIconName } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamNavItemType } from '@/views/composables/models/components/GamNav';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';

const navigationStore = useNavigationStore();
const { isCollapsed, isMoreOpened } = storeToRefs(navigationStore);
const authStore = useAuthStore();
const { isAuthenticated, userDetails } = storeToRefs(authStore);
const mapStore = useMapStore();
const { currentLocation, currentZoom } = storeToRefs(mapStore);

const route = useRoute();

onBeforeMount(() => {
  if (window.innerWidth <= 1180) {
    navigationStore.isCollapsed = true;
  }

  navigationStore.setNavItems(gambits.configService.getMainMenu());
  authStore.fetchUserInfo(true);
});

const getProfileName = (nav: GamNavItemType): string => {
  if (nav.name === RouterNameType.PROFILE && !isAuthenticated.value) {
    return 'app.nav.login';
  } else {
    return nav.displayName;
  }
};

const getNavItem = (navItem: GamNavItemType): GamNavItemType => {
  // TODO: Map location is the source of lat lng, not user location
  let query;
  if (navItem.name === 'locations' && mapStore.currentLocation) {
    query = { ...mapStore.currentLocation, zoom: mapStore.currentZoom };
  }

  return {
    ...navItem,
    query,
    displayName: getProfileName(navItem),
  };
};

const getNavClasses = computed(() => ({
  'is-translucent': isMobile.value && route.name === RouterNameType.LOCATIONS,
  'is-opaque': isMobile.value && route.name !== RouterNameType.LOCATIONS,
  'is-mobile': isMobile.value,
  'is-collapsed': isCollapsed.value,
  'is-footer': showNavFooter.value,
}));

const navItems = computed(() => [
  {
    name: RouterNameType.PROFILE,
    displayName: userDetails.value?.username ? `${userDetails.value?.username}` : 'app.nav.profile',
    icon: GamIconName.PROFILE_EMPTY,
    position: 0,
    showBottom: true,
    isRestricted: true,
    isActive: true,
  },
]);

const getAppLogo = computed((): string => {
  return isCollapsed.value ? '/images/logo-gambits-c.svg' : '/images/logo-gambits.svg';
});

const showNavFooter = computed((): boolean => {
  return !isMobile.value && !authStore.isRegistered();
});
</script>

<style scoped lang="scss">
$message-pop-margin: 6px;

.gam-nav {
  display: flex;
  padding: var(--spacing-xl) 20px var(--spacing-menu) 20px;
  background-color: var(--color-dark-600);
  width: 240px;
  min-width: 240px;
  min-height: 100%;
  border-right: 1px solid var(--color-dark-500);
  transition: all var(--transition);
  z-index: 700;
  flex-shrink: 0;

  .gam-nav-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .gam-logo {
      width: 100%;
      padding: 0 var(--spacing-md);
      overflow: hidden;
      align-items: center;
      justify-content: center;
      display: flex;
      margin-bottom: var(--spacing-menu);

      img {
        width: 144px;
        min-height: 36px;
        max-height: 36px;
        transition: width var(--transition);
      }
    }

    .gam-nav-items {
      display: flex;
      flex-direction: inherit;
      min-width: 68px;
      gap: var(--spacing-xs);
      flex: 1 1 auto;
    }

    .gam-nav-items-bottom {
      display: flex;
      flex-direction: column;
      min-width: 68px;
      gap: var(--spacing-xs);
      padding-top: var(--spacing-menu);
    }
  }

  &.is-collapsed {
    width: 108px;
    min-width: 108px;
  }

  &.is-translucent {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: linear-gradient(180deg, rgba(#1d1d1d, 0.66) 0%, rgba(#1d1d1d, 1) 90%);
  }

  &.is-opaque {
    background: #171716;
  }

  &.is-mobile {
    width: 100%;
    transition:
      opacity var(--transition-router),
      bottom var(--transition);
    border: 0;
    position: fixed;
    min-height: 68px;
    padding: 0 20px;
    bottom: 0;

    &.side-panel-opened {
      pointer-events: none;
      opacity: 0;
      bottom: -78px !important;
    }

    .gam-nav-container {
      flex-direction: column;

      .gam-nav-items {
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        min-height: 0;
        flex: auto;
        gap: 0;
      }

      .gam-mobile-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -0.5rem;

        a {
          color: #999;
          text-decoration: none;
          font-size: 13px;
          padding: 0.5rem;
        }
      }
    }

    .gam-nav-more-container {
      position: absolute;
      width: 220px;
      background-color: var(--color-dark-700);
      border: 1px solid var(--color-white-5);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      border-radius: var(--radius-large);
      padding: var(--spacing-l);
      bottom: 86px;
      right: 10px;

      .gam-nav-items-more {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-md);

        .gam-nav-item {
          width: 100%;
        }
      }
    }
  }

  &.is-footer {
    .gam-nav-container {
      .gam-nav-items-bottom {
        padding: var(--spacing-menu) 0;
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity var(--transition);
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>

<style lang="scss">
.gam-nav.is-mobile {
  .nav-label {
    display: none !important;
    overflow: hidden;
    width: 0;
  }

  .gam-nav-item-container,
  .gam-nav-item-link {
    width: 68px !important;
    height: 68px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.gam-nav.is-collapsed {
  .gam-nav-footer {
    .footer-items {
      padding: 0;

      .footer-item {
        width: 100%;

        &.has-icon {
          .nav-label {
            display: none;
          }
        }
      }
    }
  }
}
</style>
