<template>
  <div :class="['gam-nav-item', getClasses]" @click="emitNavEvent">
    <div v-if="showMore" class="gam-nav-item-container">
      <gam-nav-item-link v-bind="getNavLinkItem()" :show-more="true" />
    </div>
    <router-link v-else :id="name" :to="{ name: props.name, query: props.query }" class="gam-nav-item-container">
      <gam-nav-item-link v-bind="getNavLinkItem()" />
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { isMobile } from '@/core/helpers/ui.helper';
import { useNavigationStore } from '@/stores/navigation.store';
import GamNavItemLink from '@/views/components/gam-nav/GamNavItemLink.vue';
import { GamNavVariant } from '@/views/composables/constants/components/gamNav.constants';
import type { GamNavItemLinkType, GamNavItemType } from '@/views/composables/models/components/GamNav';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const props = defineProps<GamNavItemType>();

const navigationStore = useNavigationStore();
const { isCollapsed, isMoreOpened } = storeToRefs(navigationStore);

const emitNavEvent = (): void => {
  if (props.showMore) {
    navigationStore.setMoreOpened(!isMoreOpened.value);
  }
};

const getNavLinkItem = (): GamNavItemLinkType => {
  return {
    name: props.name.toString(),
    icon: props.icon,
    displayName: props.displayName,
    variant: GamNavVariant.PRIMARY,
    position: props.position,
    link: props.name,
    isMore: props.isMore,
    showMore: props.showMore,
    messages: props.messages,
    isIconOnly: isCollapsed.value,
    isMobile: isMobile.value,
  };
};

const getClasses = computed(() => ({
  collapsed: isCollapsed.value && !isMobile.value,
  mobile: isMobile.value,
}));
</script>

<style scoped lang="scss">
.gam-nav-item {
  display: flex;
  align-items: center;
  width: 100%;

  &.mobile {
    width: max-content;
  }

  .gam-nav-item-container {
    width: inherit;
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: var(--spacing-md);
    text-decoration: none;
  }
}
</style>
