import type {
  AuthMethodItem,
  CookiesConfig,
  GMapConfig,
  UIConfig,
  UIFooterItem,
  UIMainMenuItem,
  UIMyProfileLinkItem,
  UIMyProfileSectionItem,
  UISettings,
  UITabItem,
} from '@/core/data/config/uiConfig.interface';
import type { StorageRepositoryInterface } from '@/core/data/storage/storage.interface';
import { StorageKeyType } from '@/core/data/storage/storage.interface';
import { createLogger } from '@/core/helpers/logger.helper';
import gambitMap from '@/ui/gambitMap.json';
import manifest from '@/ui/manifest.json';

const logger = createLogger('UIConfigRepository');

export class UIConfigRepository {
  private readonly storage: StorageRepositoryInterface;

  constructor(storage: StorageRepositoryInterface) {
    this.storage = storage;
  }

  parseUIConfig(): UIConfig | null {
    let uiConfig = null;
    if (manifest) {
      uiConfig = JSON.parse(JSON.stringify(manifest)) as UIConfig;
    }
    return uiConfig;
  }

  parseMainMenu(): UIMainMenuItem[] | null {
    let navigation: UIMainMenuItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.mainMenuItems) {
      navigation = theme.mainMenuItems;
    }
    return navigation.sort((itemA, itemB) => {
      return (itemA.position || 0) < (itemB.position || 0) ? -1 : 1;
    });
  }

  parseMyProfileSections(): UIMyProfileSectionItem[][] | null {
    let sectionItems: UIMyProfileSectionItem[][] = [];
    const theme = this.parseUIConfig();
    if (theme?.myProfileSections) {
      sectionItems = theme.myProfileSections;
    }
    return sectionItems.map((section) => {
      return section
        .sort((itemA, itemB) => {
          return itemA.position < itemB.position ? -1 : 1;
        })
        .filter((item) => !item.hidden);
    });
  }

  parseMyProfileLinks(): UIMyProfileLinkItem[] | null {
    let links: UIMyProfileLinkItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.myProfileLinks) {
      links = theme.myProfileLinks;
    }
    return links.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  parseFooterItems(): UIFooterItem[] | null {
    let items: UIFooterItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.footerItems) {
      items = theme.footerItems;
    }
    return items.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  parseAuthMethods(): AuthMethodItem[] | null {
    let methods: AuthMethodItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.authMethods) {
      methods = theme.authMethods;
    }
    return methods.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  parsePlayerViewTabItems(): UITabItem[] | null {
    let tabs: UITabItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.playersListTabItems) {
      tabs = theme.playersListTabItems;
    }
    return tabs.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  parseLocationDetailTabItems(): UITabItem[] | null {
    let tabs: UITabItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.locationDetailTabItem) {
      tabs = theme.locationDetailTabItem;
    }
    return tabs.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  parseGambitsViewTabItems(): UITabItem[] | null {
    let tabs: UITabItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.gambitsListTabItems) {
      tabs = theme.gambitsListTabItems;
    }
    return tabs.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  parseClubViewTabItems(): UITabItem[] | null {
    let tabs: UITabItem[] = [];
    const theme = this.parseUIConfig();
    if (theme?.clubListTabItems) {
      tabs = theme.clubListTabItems;
    }
    return tabs.sort((itemA, itemB) => {
      return itemA.position < itemB.position ? -1 : 1;
    });
  }

  setPortalSettings(portalSettings: UISettings): void {
    this.storage.set<UISettings>(StorageKeyType.UISettings, portalSettings);
  }

  getUISettings(): UISettings | null {
    try {
      return (this.storage.get<UISettings>(StorageKeyType.UISettings) as UISettings) || null;
    } catch (error) {
      logger.error(error);
      return null;
    }
  }

  setLanguage(language: string): void {
    const uiSettings = this.getUISettings();
    if (uiSettings) {
      uiSettings.language = language;
      this.setPortalSettings(uiSettings);
    } else {
      this.setPortalSettings({ language });
    }
  }

  getLanguage(): string | null {
    const uiSettings = this.getUISettings();
    return uiSettings?.language || null;
  }

  parseGMapConfig(): GMapConfig | null {
    let mapConfig = null;
    if (gambitMap) {
      mapConfig = JSON.parse(JSON.stringify(gambitMap)) as GMapConfig;
    }
    return mapConfig;
  }

  saveCookieConfig(cookies: CookiesConfig): void {
    this.storage.set<CookiesConfig>(StorageKeyType.Cookies, cookies);
  }

  getCookieConfig(): CookiesConfig | null {
    return this.storage.get<CookiesConfig>(StorageKeyType.Cookies);
  }
}
