<template>
  <button v-touch:tap="emitButtonEvent" :class="['gam-button', size, getClasses]" @click="emitButtonEvent">
    <div :class="['gam-button-container', getContainerClasses]">
      <div v-if="isLoading" class="gam-button-load">
        <gam-icon class="load-icon" v-bind="getLoadIcon" />
      </div>

      <div class="gam-button-wrapper">
        <div v-if="getLeftIcon" class="icon icon-left">
          <gam-icon v-bind="getLeftIcon" @click="clickIconLeft" />
        </div>

        <div v-if="!(isIconOnly && leftIcon)" class="content" :class="{ sub: subLabel }">
          <div v-if="getChipProps" class="chip">
            <gam-chip v-bind="getChipProps" />
          </div>

          <div v-if="!label" class="label">
            <slot />
          </div>

          <div v-if="label" class="label" v-html="localize(label)" />

          <div v-if="subLabel" class="sub-label" v-html="localize(subLabel)" />
        </div>

        <div v-if="getRightIcon && !isIconOnly" class="icon icon-right">
          <gam-icon v-bind="getRightIcon" @click="clickIconRight" />
        </div>
      </div>
    </div>

    <!-- <transition name="dropdown" mode="out-in">
      <gam-button-message v-if="message" v-bind="message" />
    </transition> -->
  </button>
</template>

<script lang="ts" setup>
import { localize } from '@/core/gambits';
import GamChip from '@/views/components/GamChip.vue';
import GamIcon from '@/views/components/GamIcon.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamChipSize } from '@/views/composables/constants/components/gamChip.constants';
import { GamIconName, GamIconSize } from '@/views/composables/constants/components/gamIcon.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { GamChipType } from '@/views/composables/models/components/GamChip';
import type { GamIconType } from '@/views/composables/models/components/GamIcon';
import { computed } from 'vue';

const props = withDefaults(defineProps<GamButtonType>(), {
  size: GamButtonSize.LARGE,
  variant: GamButtonVariant.PRIMARY,
});

const isPrimary = (): boolean => {
  if (props.isIconPrimary) return true;
  return !props.isIconWhite && props.variant === GamButtonVariant.TERTIARY && props.size !== GamButtonSize.ACTIONS;
};

const getIconValues = (name: GamIconName): GamIconType => {
  return {
    name,
    isDark: props.variant === GamButtonVariant.PRIMARY,
    isPrimary: isPrimary(),
  };
};

const getClasses = computed(() => ({
  skeleton: props.isSkeleton,
  'full-size': props.isFullSize,
}));

const getContainerClasses = computed(() => ({
  'is-icon': props.isIconOnly,
  'is-disabled': props.isDisabled,
  'is-loading': props.isLoading,
  'is-active': props.isActive,
  'is-primary': props.variant === GamButtonVariant.PRIMARY,
  'is-secondary': props.variant === GamButtonVariant.SECONDARY,
  'is-tertiary': props.variant === GamButtonVariant.TERTIARY,
  'is-slab': props.variant === GamButtonVariant.SLAB,
  'with-chip': props.chip,
  flag: !!props.flagIcon,
  center: props.center,
  left: !props.leftIcon && !props.flagIcon,
  right: !props.rightIcon,
}));

const getChipProps = computed((): GamChipType | undefined => {
  if (props.chip) {
    return {
      ...props.chip,
      size: props.size === GamButtonSize.LARGE ? GamChipSize.NORMAL : GamChipSize.SMALL,
    };
  }
  return undefined;
});

const getLeftIcon = computed((): GamIconType | undefined => {
  if (props.leftIcon) {
    return getIconValues(props.leftIcon);
  } else if (props.flagIcon) {
    return {
      name: props.flagIcon,
      isFlag: true,
      size: GamIconSize.NORMAL,
    };
  }
  return undefined;
});

const getRightIcon = computed((): GamIconType | undefined => {
  if (props.rightIcon) {
    return getIconValues(props.rightIcon);
  }
  return undefined;
});

const getLoadIcon = computed((): GamIconType => {
  return {
    name: GamIconName.LOADING,
    isPrimary: true,
  };
});

const clickIconLeft = (event: MouseEvent) => {
  if (props.callbackIconLeft) {
    event?.preventDefault();
    event?.stopPropagation();
    props.callbackIconLeft();
  }
};

const clickIconRight = (event: MouseEvent) => {
  if (props.callbackIconRight) {
    event?.preventDefault();
    event?.stopPropagation();
    props.callbackIconRight();
  }
};

const emitButtonEvent = (event: MouseEvent): void => {
  if (props.isDisabled || typeof props.onClick !== 'function') return;

  event.stopPropagation();
  event.preventDefault();
  props.onClick(event);
};
</script>

<style lang="scss">
@use '@/ui/css/partial';

$large-size: 52px;
$small-size: 34px;
$x-small-size: 24px;

.gam-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  position: relative;
  background: transparent;
  color: var(--color-white-100);
  border-width: 0;
  padding: 0;
  font-family: 'Hanken Grotesk', sans-serif;

  &.full-size {
    align-self: stretch;
    flex: 1 0 0;
    width: var(--button-full-width, 100%);

    .gam-button-container {
      justify-content: flex-start;

      &.is-icon {
        width: 100% !important;
      }
    }

    &.large {
      .gam-button-container {
        &.is-icon {
          width: 100% !important;
        }
      }
    }
  }

  &.large {
    .gam-button-container {
      padding: 0 var(--spacing-xxl);
      min-width: $large-size;
      min-height: $large-size;

      &.flag {
        padding-left: calc(var(--spacing-xxl) + 6px);
      }

      .gam-button-wrapper {
        .content {
          min-height: 26px;

          .label {
            font-size: 16px;
          }
        }
      }

      .icon {
        &-left {
          left: 16px;
        }

        &-right {
          right: 16px;
        }
      }

      &.is-primary {
        &:before {
          background: var(--color-linear-gradient);
          opacity: 0.7;
          inset: -5px;
          padding: 5px;
        }

        &.is-loading,
        &.is-active,
        &:hover {
          &:before {
            opacity: 1;
            inset: -2px;
            padding: 2px;
          }
        }
      }

      &.is-icon {
        width: $large-size !important;
        height: $large-size !important;
      }

      &.is-loading {
        .gam-button-wrapper {
          min-height: 26px;
        }
      }
    }
  }

  &.small {
    .gam-button-container {
      min-width: $small-size;
      min-height: $small-size;

      &.is-primary {
        margin: 3px;

        &:before {
          background: var(--color-linear-gradient);
          opacity: 0.7;
          inset: -3px;
          padding: 3px;
        }

        &.is-loading,
        &.is-active,
        &:hover {
          &:before {
            opacity: 1;
            inset: -1.5px;
            padding: 1.5px;
          }
        }
      }
    }
  }

  &.actions,
  &.x-small {
    .gam-button-container {
      min-width: $small-size;
      min-height: $small-size;

      &:before {
        background: var(--color-white-100) !important;
        opacity: 0 !important;
      }

      &.is-primary {
        &.is-loading,
        &.is-active,
        &:hover {
          background-color: var(--color-white-70);

          &:before {
            opacity: 1 !important;
          }
        }
      }

      &.is-secondary {
        background-color: var(--color-white-5);

        &.is-loading,
        &.is-active,
        &:hover {
          background-color: var(--color-white-10);

          &:before {
            opacity: 0.3 !important;
          }
        }
      }

      &.with-chip {
        padding: 0 var(--spacing-xl);
      }
    }
  }

  &.x-small {
    .gam-button-container {
      min-width: $x-small-size;
      min-height: $x-small-size;

      &.is-icon {
        width: $x-small-size !important;
        height: $x-small-size !important;
      }
    }
  }

  .gam-button-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-large);
    padding: 0 var(--spacing-xl);
    cursor: pointer;
    position: relative;
    overflow: visible;
    transition: all var(--transition);
    @extend .gam-special-border !optional;

    &.left {
      padding-left: var(--spacing-l) !important;
    }

    &.right {
      padding-right: var(--spacing-l) !important;
    }

    .gam-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 0;

      .content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 20px;
        gap: var(--spacing-xs);

        &.sub {
          align-items: flex-start;
          flex-direction: column;
        }

        .label {
          font-size: 14px;
          font-weight: 700;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          align-content: center;
          word-break: break-all;
          padding: 1px 0;

          span {
            color: var(--color-white-30);
          }
        }

        .sub-label {
          font-size: 12px;
          font-weight: 400;
          color: var(--color-white-70);
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }

    .gam-button-load {
      position: absolute;
      visibility: hidden;

      .load-icon {
        animation: loader 1000ms var(--cubic-bezier) infinite;

        @keyframes loader {
          0% {
            rotate: 0deg;
          }
          100% {
            rotate: 360deg;
          }
        }
      }
    }

    .icon {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      &-left {
        left: 8px;
      }

      &-right {
        right: 8px;
      }
    }

    &.center {
      justify-content: center !important;

      .gam-button-wrapper {
        justify-content: center;
      }
    }

    &.is-primary {
      background-color: var(--color-white-100);
      color: var(--color-dark-700);
    }

    &.is-secondary {
      background-color: var(--color-dark-70);
      -webkit-backdrop-filter: blur(15px);
      backdrop-filter: blur(15px);

      &:before {
        background: var(--color-linear-gradient);
        opacity: 0.7;
      }

      &.is-active,
      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    &.is-tertiary {
      color: var(--color-white-100);
      background-color: transparent !important;

      &.is-active,
      &:hover {
        opacity: 0.7;
      }
    }

    &.is-slab {
      background: var(--color-dark-50) !important;
      backdrop-filter: blur(15px);

      &:hover {
        background: var(--color-dark-500) !important;
      }
    }

    &.is-icon {
      width: $small-size !important;
      height: $small-size !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .icon {
        position: relative !important;
        left: 0 !important;
      }
    }

    &.is-disabled,
    &.is-loading {
      cursor: default;
      user-select: none;
      pointer-events: none;
    }

    &.is-loading {
      .gam-button-load {
        visibility: visible;
      }

      .gam-button-wrapper {
        position: relative;
        visibility: hidden;
        min-height: 20px;
      }
    }

    &.is-primary.is-disabled {
      opacity: 0.3;
      filter: saturate(0);
      user-select: none !important;
    }

    &:not(.is-primary).is-disabled {
      color: var(--color-white-30);
    }

    &.with-chip {
      .content {
        justify-content: normal;

        .label {
          text-align: end;
          flex: 1;
        }
      }
    }

    &.flag {
      padding-left: calc(var(--spacing-xl) + 6px);
    }
  }
}
</style>
