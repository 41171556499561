import type { JWT } from '@/core/data/auth/auth.interface';
import type { JwtService } from '@/core/domain/auth/jwt.service';
import { createLogger } from '@/core/helpers/logger.helper';
import type { GamPromises, GamRequest, GamRequestConfig, GamResponse } from '@/core/network/http/httpClient.interface';
import { HttpError } from '@/core/network/http/httpError';
import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';

const logger = createLogger('HttpClient');

export class HttpClient {
  private readonly serviceUrl: string;
  private readonly jwtService: JwtService;
  private readonly instance: AxiosInstance;
  private failedRequests: GamPromises[] = [];
  private isRefreshing = false;

  constructor(jwtService: JwtService, serviceUrl: string) {
    this.serviceUrl = serviceUrl;
    this.jwtService = jwtService;
    this.instance = axios.create({
      validateStatus: (status) => status < 400,
      timeout: 30000,
    });
    this.setResponseInterceptors();
  }

  private formatUrl(url: string, params?: { [key: string]: string }): string {
    if (!params) return url;

    let result = url;
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const placeholder = `{${key}}`;
        const value = params[key];
        result = result.replace(new RegExp(placeholder, 'g'), value);
      }
    }
    return result;
  }

  private setUrl(url: string, params?: { [key: string]: string }): string {
    return `${this.serviceUrl}${this.formatUrl(url, params)}`;
  }

  private setConfig<P>(config?: GamRequestConfig<P>): GamRequestConfig<P> {
    return this.jwtService.getAuthorizationConfig<P>(config);
  }

  async get<R, P = any>(url: string, request?: GamRequest<P>): Promise<AxiosResponse<GamResponse<R>>> {
    logger.debug('.get(...)', { url });
    return this.instance.get<GamResponse<R>>(this.setUrl(url, request?.urlParams), this.setConfig(request?.config));
  }

  async post<R, T, P = any>(url: string, request: GamRequest<P, T>): Promise<AxiosResponse<GamResponse<R>>> {
    logger.debug('.post(...)', { url });
    return this.instance.post<GamResponse<R>>(
      this.setUrl(url, request?.urlParams),
      request.data,
      this.setConfig(request?.config),
    );
  }

  async put<R, T, P = any>(url: string, request: GamRequest<P, T>): Promise<AxiosResponse<GamResponse<R>>> {
    logger.debug('.put(...)', { url });
    return this.instance.put<GamResponse<R>>(
      this.setUrl(url, request.urlParams),
      request.data,
      this.setConfig(request?.config),
    );
  }

  async delete<R, P = any>(url: string, request?: GamRequest<P>): Promise<AxiosResponse<GamResponse<R>>> {
    logger.debug('.delete(...)', { url });
    return this.instance.delete<GamResponse<R>>(this.setUrl(url, request?.urlParams), this.setConfig(request?.config));
  }

  async patch<R, T, P = any>(url: string, request: GamRequest<P, T>): Promise<AxiosResponse<GamResponse<R>>> {
    logger.debug('.patch(...)', { url });
    return this.instance.patch<GamResponse<R>>(
      this.setUrl(url, request?.urlParams),
      request.data,
      this.setConfig(request?.config),
    );
  }

  private setResponseInterceptors(): void {
    this.instance.interceptors.response.use(
      (response) => {
        if (response?.data && !response?.data?.data) {
          const tempData = response.data;
          response.data = {};
          response.data.data = tempData;
        }
        return response;
      },
      async (error) => {
        if (error.response?.status === 401 && !error.config.retryRequest) {
          if (this.isRefreshing) {
            try {
              const tokenInfo: JWT | null = await new Promise((resolve, reject) => {
                this.failedRequests.push({ resolve, reject });
              });
              error.config.headers.authorization = `Bearer ${tokenInfo?.accessToken}`;
              return this.instance(error.config);
            } catch (e) {
              return e;
            }
          }
          this.isRefreshing = true;
          error.config.retryRequest = true;
          return new Promise((resolve, reject) => {
            this.jwtService
              .refreshToken()
              .then(() => {
                const tokenInfo = this.jwtService.getTokenInfo();
                error.config.headers.authorization = `Bearer ${tokenInfo?.accessToken}`;
                this.isRefreshing = false;
                this.processQueue(null, tokenInfo);
                resolve(this.instance(error.config));
              })
              .catch((e) => {
                this.processQueue(e);
                reject(error.response);
              });
          });
        }
        throw new HttpError(error.response);
      },
    );
  }

  private processQueue(error: AxiosError | null, token: JWT | null = null) {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedRequests = [];
  }
}
