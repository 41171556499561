import { RouterAdminType, RouterAuthType, RouterNameType, StaticPageName } from '@/core/data/config/uiConfig.interface';
import { gambits } from '@/core/gambits';
import { useNavigationStore } from '@/stores/navigation.store';
import { createRouter, createWebHistory, type NavigationGuardWithThis } from 'vue-router';

const MainView = () => import('@/views/MainView.vue');
const ClubsView = () => import('@/views/main/clubs/ClubsView.vue');
const ClubView = () => import('@/views/main/clubs/ClubView.vue');
const PlayersView = () => import('@/views/main/players/PlayersView.vue');
const GambitsView = () => import('@/views/main/gambits/GambitsView.vue');
const GambitDetailView = () => import('@/views/main/gambits/GambitDetailView.vue');
const LocationsView = () => import('@/views/main/locations/LocationsView.vue');
const ProfileView = () => import('@/views/main/profile/ProfileView.vue');
const AuthView = () => import('@/views/auth/AuthView.vue');
const AuthSelection = () => import('@/views/auth/pages/AuthSelection.vue');
const AuthMail = () => import('@/views/auth/pages/AuthMail.vue');
const AuthOtp = () => import('@/views/auth/pages/AuthOtp.vue');
const AuthRegister = () => import('@/views/auth/pages/AuthRegistration.vue');
const AuthRatings = () => import('@/views/auth/pages/AuthRatings.vue');
const StaticPage = () => import('@/views/StaticPage.vue');
const AdminView = () => import('@/views/admin/AdminView.vue');
const AdminLocations = () => import('@/views/admin/AdminLocations.vue');
const AdminUsers = () => import('@/views/admin/AdminUsers.vue');
const AdminReports = () => import('@/views/admin/AdminReports.vue');

const redirectToRegister: NavigationGuardWithThis<undefined> = (to, from, next) => {
  const navStore = useNavigationStore();
  const otpData = gambits.authService.getOtpMailData();
  navStore.setNavVisible(false);

  if (gambits.authService.shouldUserRegister()) {
    next({ name: RouterAuthType.AUTH_REGISTRATION });
  } else if (to?.name === RouterAuthType.AUTH_OTP && !otpData?.email) {
    next({ name: RouterAuthType.AUTH_EMAIL });
  } else {
    next();
  }
};

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',
  routes: [
    {
      path: '/players',
      name: RouterNameType.PLAYERS,
      component: PlayersView,
    },
    {
      path: '/club/:username?',
      name: RouterNameType.CLUB,
      component: ClubView,
    },
    {
      path: '/clubs/:username?',
      name: RouterNameType.CLUBS,
      component: ClubsView,
    },
    {
      path: '/gambit/:id',
      name: RouterNameType.GAMBIT,
      component: GambitDetailView,
    },
    {
      path: '/gambits/:id?',
      name: RouterNameType.GAMBITS,
      component: GambitsView,
    },
    {
      path: '/feed',
      name: RouterNameType.FEED,
      component: MainView,
    },
    {
      path: '/',
      name: RouterNameType.LOCATIONS,
      component: LocationsView,
    },
    {
      path: '/#more',
      name: RouterNameType.MORE,
      component: MainView,
    },
    {
      path: '/messages',
      name: RouterNameType.MESSAGES,
      component: MainView,
    },
    {
      path: '/notifications',
      name: RouterNameType.NOTIFICATIONS,
      component: MainView,
    },
    {
      path: '/results',
      name: RouterNameType.RESULTS,
      component: MainView,
    },
    {
      path: '/profile',
      name: RouterNameType.PROFILE,
      component: ProfileView,
      beforeEnter: (to, from, next) => {
        if (gambits.authService.isAuthenticated()) {
          next();
        } else {
          next({ name: RouterAuthType.AUTH_SELECT });
        }
      },
    },
    {
      path: '/auth',
      name: RouterAuthType.AUTH,
      component: AuthView,
      children: [
        {
          path: '',
          name: RouterAuthType.AUTH_SELECT,
          component: AuthSelection,
          beforeEnter: redirectToRegister,
        },
        {
          path: 'email',
          name: RouterAuthType.AUTH_EMAIL,
          component: AuthMail,
          beforeEnter: redirectToRegister,
        },
        { path: 'otp', name: RouterAuthType.AUTH_OTP, component: AuthOtp, beforeEnter: redirectToRegister },
        {
          path: 'register',
          name: RouterAuthType.AUTH_REGISTRATION,
          component: AuthRegister,
          beforeEnter: (to, from, next) => {
            if (gambits.authService.isAuthenticated()) {
              next();
            } else {
              next({ name: RouterAuthType.AUTH_SELECT });
            }
          },
        },
        {
          path: 'ratings',
          name: RouterAuthType.AUTH_RATINGS,
          component: AuthRatings,
          beforeEnter: (to, from, next) => {
            if (gambits.authService.isAuthenticated()) {
              if (gambits.authService.getFormData()) {
                next();
              } else {
                next({ name: RouterAuthType.AUTH_REGISTRATION });
              }
            } else {
              next({ name: RouterAuthType.AUTH_SELECT });
            }
          },
        },
      ],
    },
    {
      path: '/terms-and-conditions',
      name: StaticPageName.TERMS,
      component: StaticPage,
    },
    {
      path: '/privacy',
      name: StaticPageName.PRIVACY_POLICY,
      component: StaticPage,
    },
    {
      path: '/imprint',
      name: StaticPageName.IMPRINT,
      component: StaticPage,
    },
    {
      path: '/contact',
      name: StaticPageName.CONTACT,
      component: StaticPage,
    },
    {
      path: '/admin',
      name: RouterNameType.ADMIN,
      component: AdminView,
      redirect: { name: RouterAdminType.USERS },
      children: [
        {
          path: 'locations',
          name: RouterAdminType.LOCATIONS,
          component: AdminLocations,
        },
        {
          path: 'users',
          name: RouterAdminType.USERS,
          component: AdminUsers,
        },
        {
          path: 'reports',
          name: RouterAdminType.REPORTS,
          component: AdminReports,
        },
      ],
    },
    {
      path: '/@:username',
      name: RouterNameType.USERNAME,
      component: ClubView,
      // redirect: { name: RouterNameType.CLUB },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isAuth = gambits.authService.isAuthenticated();
  const isRegistered = gambits.authService.isRegistered();
  const isAdmin = gambits.authService.isAdmin();
  const toName = to?.name?.toString() || '';
  const isRestricted = gambits.configService.isRestricted(toName);
  const isActive = gambits.configService.isActive(toName);
  const isAuthRoute = Object.values(RouterAuthType).includes(toName as RouterAuthType);
  const isAdminRoute = Object.values(RouterAdminType).includes(toName as RouterAdminType);
  const isStaticRoute = Object.values(StaticPageName).includes(toName as StaticPageName);
  const isNamedRoute = Object.values(RouterNameType).includes(toName as RouterNameType);
  const navStore = useNavigationStore();
  navStore.setMoreOpened(false);
  if (!isActive && !isAuthRoute && !isStaticRoute && !isAdminRoute && !isNamedRoute) {
    next(from);
  } else if ((isRegistered && isAuthRoute) || (isAdminRoute && !isAdmin)) {
    navStore.setNavVisible(true);
    next({ name: RouterNameType.LOCATIONS });
  } else if ((isAuth && isRegistered) || !isRestricted) {
    if (!isAuthRoute) navStore.setNavVisible(true);
    next();
  } else if (isAuth) {
    navStore.setNavVisible(false);
    next({ name: RouterAuthType.AUTH_REGISTRATION });
  } else {
    navStore.setNavVisible(false);
    next({ name: RouterAuthType.AUTH_SELECT });
  }
});

export default router;
