<template>
  <div class="gam-nav-footer">
    <div v-if="footerItems" class="footer-items">
      <div
        v-for="(item, i) in footerItems"
        :key="`f-it-${i}`"
        :class="['footer-item', { 'has-icon': item.icon }]"
        @click="openLink(item)"
      >
        <div v-if="item.icon" class="nav-icon">
          <gam-icon :name="item.icon" />
          <span v-if="item.displayName" class="nav-label">{{ localize(item.displayName) }}</span>
        </div>
        <div v-else-if="item.displayName" class="nav-label">
          {{ localize(item.displayName) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UIFooterItem } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import NavigationHelper from '@/core/helpers/navigation.helper';
import GamIcon from '@/views/components/GamIcon.vue';
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';

const footerItems = ref<UIFooterItem[] | null>(null);
const router = useRouter();

onBeforeMount(() => {
  footerItems.value = gambits.configService.getFooterItems();
});

const openLink = async (link: UIFooterItem) => {
  await NavigationHelper.openLink(router, link);
};
</script>

<style scoped lang="scss">
.gam-nav-footer {
  display: flex;
  flex-direction: column;
  min-width: 68px;
  gap: var(--spacing-xs);
  overflow: hidden;

  .footer-items {
    display: flex;
    width: 100%;
    padding: 0 26px;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    .footer-item {
      width: 50%;
      cursor: pointer;
      margin-top: 1rem;

      &.has-icon {
        width: 100%;

        .nav-icon {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 0.5rem;
        }

        .nav-label {
          font-size: 12px;
        }
      }

      .nav-label {
        color: var(--color-white-30);
        font-size: 14px;
        font-weight: 400;
        line-height: 110%;
      }

      &:hover {
        .nav-label {
          color: var(--color-white-100);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.gam-nav-footer {
  .nav-icon {
    svg g {
      fill: var(--color-white-30);
    }

    &:hover svg g {
      fill: var(--color-white-100);
    }
  }
}
</style>
