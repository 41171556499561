<template>
  <div class="gam-qqe-notice" :class="{ mobile: isMobile }">
    <div class="qqe-message">
      {{ localize('app.cookies.notification.message') }}
    </div>
    <div class="qqe-actions">
      <gam-button v-bind="getCustomizeButton" />
      <gam-button v-bind="getAcceptButton" />
    </div>
    <gam-dialog ref="dialog">
      <cookies-dialog v-bind="cookieDialog" />
    </gam-dialog>
  </div>
</template>

<script setup lang="ts">
import type { CookiesConfig } from '@/core/data/config/uiConfig.interface';
import { gambits, localize } from '@/core/gambits';
import { isMobile } from '@/core/helpers/ui.helper';
import GamButton from '@/views/components/GamButton.vue';
import GamDialog from '@/views/components/gam-dialog/GamDialog.vue';
import CookiesDialog from '@/views/components/gam-dialog/dialog-contents/CookiesDialog.vue';
import { GamButtonSize, GamButtonVariant } from '@/views/composables/constants/components/gamButton.constants';
import { GamComponentsEmits } from '@/views/composables/constants/main/emit.constants';
import type { GamButtonType } from '@/views/composables/models/components/GamButton';
import type { CookieDialogType } from '@/views/composables/models/dialog.interface';
import { computed, ref } from 'vue';

const emits = defineEmits([GamComponentsEmits.UPDATE]);
const dialog = ref<InstanceType<typeof GamDialog>>();

const setCookieOptions = (cookies: CookiesConfig): void => {
  gambits.configService.saveCookies(cookies);
  emits(GamComponentsEmits.UPDATE, cookies);
};

const cookieDialog = computed((): CookieDialogType => {
  return {
    callback: (isPerformance: boolean) => {
      setCookieOptions({
        functional: true,
        performance: isPerformance,
      });
      dialog.value?.close();
    },
  };
});

const getCustomizeButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.SECONDARY,
    size: GamButtonSize.SMALL,
    center: true,
    label: 'app.cookies.notification.button.customize',
    isFullSize: isMobile.value,
    onClick: () => {
      dialog.value?.show();
    },
  };
});

const getAcceptButton = computed((): GamButtonType => {
  return {
    variant: GamButtonVariant.PRIMARY,
    size: GamButtonSize.SMALL,
    center: true,
    label: 'app.cookies.notification.button.accept',
    isFullSize: isMobile.value,
    onClick: () => {
      setCookieOptions({
        functional: true,
        performance: true,
      });
    },
  };
});
</script>

<style scoped lang="scss">
.gam-qqe-notice {
  display: flex;
  padding: 24px 48px;
  background: var(--color-dark-1000);
  justify-content: space-between;
  align-self: center;
  width: 100%;
  z-index: 9999;
  position: fixed;
  bottom: 0;

  &.mobile {
    top: 0;
    bottom: unset;
    gap: 16px;
    flex-direction: column;

    .qqe-message {
      text-align: center;
    }
  }

  .qqe-message {
    color: var(--color-white-100);
    font-size: 16px;
    font-weight: 300;
    line-height: 110%; /* 17.6px */
    align-self: center;
  }

  .qqe-actions {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
</style>
