import type {
  AuthUserDto,
  AuthValidateOtpPayload,
  OtpMailData,
  RegisterFormData,
  UserFormData,
} from '@/core/data/auth/auth.interface';
import type { AuthRepository } from '@/core/data/auth/auth.repository';
import type { LoginMethodType } from '@/core/data/auth/auth.type';
import type { CodeResponse } from '@/core/data/auth/google/oauth2';
import { signInMethodGroups } from '@/core/data/auth/loginMethods';
import type { AuthMethodItem } from '@/core/data/config/uiConfig.interface';
import type { UserRatings } from '@/core/data/rating/rating.interface';
import type { ConfigService } from '@/core/domain/config.service';
import { gambits } from '@/core/gambits';
import FormHelper from '@/core/helpers/form.helper';
import type { ApiError, Result } from '@/core/network/http/httpClient.interface';
import { generalError } from '@/core/network/http/httpError';
import type { AuthScreenMethodGroup } from '@/views/composables/auth/useAuth.interface';
import type { BaseUserForm } from '@/views/composables/models/form.interface';

export class AuthService {
  private readonly repository: AuthRepository;
  private readonly configService: ConfigService;
  private profileImage: string | null = null;
  private chessboardImage: string | null = null;

  constructor(repository: AuthRepository, configService: ConfigService) {
    this.repository = repository;
    this.configService = configService;
  }

  async authWithEmail(email: string): Promise<Result<boolean | null>> {
    try {
      const response = await this.repository.authWithMail(email);
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async authWithGoogle(code: CodeResponse): Promise<Result<AuthUserDto>> {
    try {
      const response = await this.repository.authGoogle(code);
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async getToken(code: string): Promise<Result<AuthUserDto>> {
    try {
      const response = await this.repository.getToken(code);
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async validateOtp(payload: AuthValidateOtpPayload): Promise<Result<AuthUserDto>> {
    try {
      const response = await this.repository.authValidateOtp(payload);
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  async register(ratings: UserRatings): Promise<Result<AuthUserDto | null>> {
    try {
      const formData = this.repository.getRegisterData();
      if (!formData) return { error: generalError() };

      const registerData: UserFormData = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        username: formData.username,
        country: formData.country,
        emoji: formData.emoji,
        birthDate: new Date(formData.date).toISOString(),
        profileImage: FormHelper.parseImageFile(this.profileImage),
        chessboardImage: FormHelper.parseImageFile(this.chessboardImage),
        ...ratings,
      };

      const response = await this.repository.registerUser(registerData);
      if (response) {
        this.clearImages();
        return { data: response };
      } else {
        return { error: generalError() };
      }
    } catch (error) {
      gambits.authService.logout();

      return {
        error: error as ApiError,
      };
    }
  }

  private clearImages(): void {
    this.profileImage = null;
    this.chessboardImage = null;
  }

  async delete(): Promise<Result<boolean>> {
    try {
      const response = await this.repository.deleteUser();
      return response ? { data: response } : { error: generalError() };
    } catch (error) {
      return {
        error: error as ApiError,
      };
    }
  }

  registerUserFormData(formData: BaseUserForm): void {
    this.repository.saveTempRegisterData({
      firstName: formData.firstName,
      lastName: formData.lastName,
      username: formData.username,
      date: formData.date ? formData.date.toISOString() : '',
      country: formData.country,
      emoji: formData.emoji || undefined,
    });
    // Images are (can be) too big to be put into local storage so we store them here.
    // It would be nice to find a better solution in the future.
    // Maybe we could upload them to the server and store the blobhash in the local storage.
    this.profileImage = formData.profileImage ?? null;
    this.chessboardImage = formData.chessboardImage ?? null;
  }

  getFormData(): RegisterFormData | null {
    return this.repository.getRegisterData();
  }

  getUser(): AuthUserDto | null {
    return this.repository.getUser();
  }

  isAuthenticated(): boolean {
    return this.repository.isAuthenticated();
  }

  isRegistered(): boolean {
    return !!this.getUser()?.isActive;
  }

  isAdmin(): boolean {
    return !!this.getUser()?.isAdmin;
  }

  shouldUserRegister(): boolean {
    return this.isAuthenticated() && !this.getUser()?.isActive;
  }

  getOtpMailData(): OtpMailData | null {
    return this.repository.getOtpMailData();
  }

  getOtpSendDelay(): number {
    const otpMailData = this.repository.getOtpMailData();
    if (otpMailData?.sendTime) {
      const otpDate = new Date(otpMailData.sendTime);
      otpDate.setSeconds(otpDate.getSeconds() + this.configService.getOtpDelay());

      // Calculate the difference in seconds
      const differenceInSeconds = Math.round((otpDate.getTime() - new Date().getTime()) / 1000);
      return differenceInSeconds > 0 ? differenceInSeconds : 0;
    }
    return 0;
  }

  private configureManifest(
    manifestConfiguration: AuthMethodItem[],
    loginMethodGroup: AuthScreenMethodGroup,
  ): AuthScreenMethodGroup {
    return {
      ...loginMethodGroup,
      items: loginMethodGroup.items
        ?.map((loginMethod) => {
          const manifestConfig = manifestConfiguration.find((el) => el.type === loginMethod.type);
          return {
            ...loginMethod,
            active: manifestConfig?.active || false,
            position: manifestConfig?.position || loginMethod.position,
            clientId: manifestConfig?.clientId,
          };
        })
        .sort((a, b) => (a.position > b.position ? 1 : -1)),
    };
  }

  getAuthMethods(): AuthScreenMethodGroup[] {
    const configMethods = this.configService.getAuthMethods();

    return signInMethodGroups
      .map((signInMethodGroup) => this.configureManifest(configMethods, signInMethodGroup))
      .sort((a, b) => (a.position > b.position ? 1 : -1));
  }

  saveConnecting(type: LoginMethodType): void {
    this.repository.setConnecting(type);
  }

  getConnecting(): LoginMethodType | null {
    return this.repository.getConnecting();
  }

  removeConnecting(): void {
    this.repository.removeConnecting();
  }

  async logout(): Promise<void> {
    return this.repository.logout();
  }
}
