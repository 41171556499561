import { LoginMethodType } from '@/core/data/auth/auth.type';
import type { ClusterConfig, SentryConfig } from '@/core/data/config/clientConfig.interface';
import type { ClientConfigRepository } from '@/core/data/config/clientConfig.repository';
import type {
  AuthMethodItem,
  CookiesConfig,
  GMapConfig,
  UIConfig,
  UIFooterItem,
  UIMainMenuItem,
  UIMyProfileLinkItem,
  UIMyProfileSectionItem,
  UITabItem,
} from '@/core/data/config/uiConfig.interface';
import type { UIConfigRepository } from '@/core/data/config/uiConfig.repository';
import type { RouteRecordName } from 'vue-router';
import type { MapMinMaxRadius, MapZoom, UserGeoData } from '../data/location/location.interface';

export class ConfigService {
  private clientConfig: ClientConfigRepository;
  private uiConfig: UIConfigRepository;

  constructor(clientConfig: ClientConfigRepository, uiConfig: UIConfigRepository) {
    this.clientConfig = clientConfig;
    this.uiConfig = uiConfig;
  }

  getServiceRoutingHost(): string {
    return this.isDevMode() ? '' : this.clientConfig.parseServiceRoutingHost();
  }

  getServiceUrl(): string {
    return this.clientConfig.parseServiceRoutingHost();
  }

  getClientVersion(): string {
    return this.clientConfig.parseClientVersion();
  }

  getClientFullVersion(): string {
    return this.clientConfig.parseClientFullVersion();
  }

  getGMapApiKey(): string {
    return this.clientConfig.parseGMapApiKey();
  }

  getGMapMapId(): string {
    return this.clientConfig.parseGMapMapId();
  }

  getDefaultGeoData(): UserGeoData {
    return this.clientConfig.parseDefaultGeoData();
  }

  getMapMinMaxRadius(): MapMinMaxRadius {
    return this.clientConfig.parseMapMinMaxRadius();
  }

  isDevMode(): boolean {
    return this.clientConfig.parseDevMode();
  }

  getDevInfo(): string {
    const version = this.getClientVersion();
    return `${version}`;
  }

  setUILanguage(language: string): void {
    return this.uiConfig.setLanguage(language);
  }

  getDefaultLanguage(): string {
    return this.clientConfig.parseDefaultLanguage();
  }

  getOtpSize(): number {
    return this.clientConfig.parseDefaultOTPSize();
  }

  getOtpDelay(): number {
    return this.clientConfig.parseDefaultOTPDelay();
  }

  getClientLanguage(): string {
    const clientLanguage = this.getDefaultLanguage();
    this.setUILanguage(clientLanguage);
    return clientLanguage;
  }

  getLanguage(): string {
    const language = this.uiConfig.getLanguage();
    return language || this.getClientLanguage();
  }

  getUIConfig(): UIConfig | null {
    return this.uiConfig.parseUIConfig();
  }

  getMainMenu(): UIMainMenuItem[] | null {
    return this.uiConfig.parseMainMenu();
  }

  getMyProfileSections(): UIMyProfileSectionItem[][] | null {
    return this.uiConfig.parseMyProfileSections();
  }

  getMyProfileLinks(): UIMyProfileLinkItem[] | null {
    return this.uiConfig.parseMyProfileLinks();
  }

  getFooterItems(): UIFooterItem[] | null {
    return this.uiConfig.parseFooterItems();
  }

  private getClientIdByType(type: LoginMethodType): string | undefined {
    switch (type) {
      case LoginMethodType.Google:
        return this.clientConfig.parseGoogleClientId();
      case LoginMethodType.Lichess:
        return this.clientConfig.parseLichessClientId();
      case LoginMethodType.Apple:
        return this.clientConfig.parseAppleClientId();
      default:
        break;
    }
  }

  getAuthMinAge(): number {
    return this.clientConfig.parseAuthMinAge();
  }

  getAuthMethods(): AuthMethodItem[] {
    return (
      this.uiConfig.parseAuthMethods()?.map((method) => {
        return {
          ...method,
          clientId: this.getClientIdByType(method.type),
        };
      }) || []
    );
  }

  isRestricted(name: RouteRecordName | null | undefined): boolean {
    const menuItem = this.getMainMenu()?.find((item) => item.name === name);
    return menuItem?.isRestricted || false;
  }

  isActive(name: RouteRecordName | null | undefined): boolean {
    const menuItem = this.getMainMenu()?.find((item) => item.name === name);
    return menuItem?.isActive || false;
  }

  isRestrictedToAdmins(name: RouteRecordName | null | undefined): boolean {
    const menuItem = this.getMainMenu()?.find((item) => item.name === name);
    return menuItem?.isRestrictedToAdmins || false;
  }

  getGMapConfig(): GMapConfig | null {
    return this.uiConfig.parseGMapConfig();
  }

  getMapZoom(): MapZoom {
    return this.clientConfig.parseMapZoom();
  }

  getPlayerViewTabItems(): UITabItem[] | null {
    return this.uiConfig.parsePlayerViewTabItems();
  }

  getGambitsViewTabItems(): UITabItem[] | null {
    return this.uiConfig.parseGambitsViewTabItems();
  }

  getClubViewTabItems(): UITabItem[] | null {
    return this.uiConfig.parseClubViewTabItems();
  }

  getLocationDetailTabItems(): UITabItem[] | null {
    return this.uiConfig.parseLocationDetailTabItems();
  }

  saveCookies(cookies: CookiesConfig): void {
    this.uiConfig.saveCookieConfig(cookies);
  }

  getCookies(): CookiesConfig | null {
    return this.uiConfig.getCookieConfig();
  }

  getSentryConfig(): SentryConfig {
    return this.clientConfig.parseSentryConfig();
  }

  getClusterConfig(): ClusterConfig {
    return this.clientConfig.parseClusterConfig();
  }
}
