import type { BaseClubDto, ClubFormData, DetailClubDto } from '@/core/data/club/club.interface';
import type { GeoLocation } from '@/core/data/location/location.interface';
import { baseClubFields, detailClubFields } from '@/core/network/api/constants/api.fields.constant';
import { ClubEndpoints } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse, GetLocationTableFilters, GetTableParams } from '@/core/network/http/httpClient.interface';
import { GamFilterSortLocation } from '@/views/composables/constants/main/filter.constants';
import type { AxiosResponse } from 'axios';

export class ClubApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getClubs(geo?: GeoLocation, afterCursor?: string | null): Promise<AxiosResponse<GamResponse<BaseClubDto[]>>> {
    const geoParams = geo
      ? {
          latitude: geo.lat,
          longitude: geo.lng,
          sortBy: GamFilterSortLocation.DISTANCE,
        }
      : {};
    return this.httpClient.get<BaseClubDto[], GetLocationTableFilters>(ClubEndpoints.GET_CLUBS, {
      config: {
        params: {
          ...geoParams,
          afterCursor,
          fields: baseClubFields.join(','),
        },
      },
    });
  }

  async followClub(id: string): Promise<AxiosResponse<GamResponse<any>>> {
    const result = await this.httpClient.post(ClubEndpoints.CLUB_FOLLOW, {
      urlParams: { id },
    });

    return result;
  }

  async unfollowClub(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(ClubEndpoints.CLUB_FOLLOW, {
      urlParams: { id },
    });
  }

  async getClub(id: string): Promise<AxiosResponse<GamResponse<DetailClubDto>>> {
    return this.httpClient.get<DetailClubDto, GetTableParams>(ClubEndpoints.GET_CLUB_DETAILS, {
      config: {
        params: {
          fields: detailClubFields.join(','),
        },
      },
      urlParams: { id },
    });
  }

  async getClubByUsername(id: string): Promise<AxiosResponse<GamResponse<DetailClubDto>>> {
    return this.httpClient.get<DetailClubDto, GetTableParams>(ClubEndpoints.GET_CLUB_DETAILS_BY_USERNAME, {
      config: {
        params: {
          fields: detailClubFields.join(','),
        },
      },
      urlParams: { id },
    });
  }

  async addClub(data: ClubFormData): Promise<AxiosResponse<GamResponse<BaseClubDto>>> {
    return await this.httpClient.post(ClubEndpoints.GET_CLUBS, {
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async updateClub(id: string, data: ClubFormData): Promise<AxiosResponse<GamResponse<BaseClubDto>>> {
    return await this.httpClient.put(ClubEndpoints.GET_CLUB_DETAILS, {
      urlParams: { id },
      data,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async deleteClub(id: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(ClubEndpoints.GET_CLUB_DETAILS, {
      urlParams: { id },
    });
  }

  async addLocation(id: string, locationId: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.post(ClubEndpoints.CLUB_LOCATIONS, {
      urlParams: { id },
      data: { locationId },
    });
  }

  async removeLocation(id: string, locationId: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(ClubEndpoints.CLUB_LOCATION, {
      urlParams: { id, locationId },
    });
  }

  async addCurator(id: string, username: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.post(ClubEndpoints.CLUB_CURATORS, {
      urlParams: { id },
      data: { username },
    });
  }

  async removeCurator(id: string, username: string): Promise<AxiosResponse<GamResponse<void>>> {
    return await this.httpClient.delete(ClubEndpoints.CLUB_CURATOR, {
      urlParams: { id, username },
    });
  }
}
