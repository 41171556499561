import type { UpdateProfileFormData } from '@/core/data/auth/auth.interface';
import type { BaseClubDto } from '@/core/data/club/club.interface';
import type { BaseUserDto, BaseUserInfo, UserDto, ValidateUserDto } from '@/core/data/user/user.interface';
import { baseUserFields, baseUserInfoFields, detailUserFields } from '@/core/network/api/constants/api.fields.constant';
import { UserEndpoint } from '@/core/network/api/constants/api.url.constant';
import { HttpClient } from '@/core/network/http/httpClient';
import type { GamResponse, GetPlayerTableFilters, GetTableParams } from '@/core/network/http/httpClient.interface';
import { useFilterStore } from '@/stores/filter.store';
import { useTableStore } from '@/stores/table.store';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import type { AxiosResponse } from 'axios';
export class UserApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  private getUsersParams(id: GamListId): GetPlayerTableFilters {
    const tableStore = useTableStore<UserDto>(id)();
    const filterStore = useFilterStore<GetPlayerTableFilters>(id)();

    return {
      ...tableStore.tableParams,
      ...tableStore.getLocationParam(),
      ...filterStore.getFilters(),
      fields: baseUserFields.join(','),
      relationship: filterStore.getActiveTab(),
    };
  }

  async getLocation(): Promise<AxiosResponse<GamResponse<any>>> {
    return this.httpClient.get<any>(UserEndpoint.GET_USER_LOCATION);
  }

  async getUsers(listId: GamListId): Promise<AxiosResponse<GamResponse<BaseUserDto[]>>> {
    return this.httpClient.get<BaseUserDto[], GetPlayerTableFilters>(UserEndpoint.USERS, {
      config: {
        params: this.getUsersParams(listId),
      },
    });
  }

  async getUser(id: string): Promise<AxiosResponse<GamResponse<UserDto>>> {
    return this.httpClient.get<UserDto, GetTableParams>(UserEndpoint.GET_USER_DETAILS, {
      urlParams: {
        id,
      },
      config: {
        params: {
          fields: detailUserFields.join(','),
        },
      },
    });
  }

  async getUserInfo(): Promise<AxiosResponse<GamResponse<BaseUserInfo>>> {
    return this.httpClient.get<BaseUserInfo>(UserEndpoint.GET_USER_INFO, {
      config: {
        params: {
          fields: baseUserInfoFields.join(','),
        },
      },
    });
  }

  async getUserClubs(): Promise<AxiosResponse<GamResponse<BaseClubDto[]>>> {
    return this.httpClient.get<BaseClubDto[]>(UserEndpoint.GET_USER_CLUBS, {
      config: {
        params: {
          fields: baseUserInfoFields.join(','),
        },
      },
    });
  }

  async updateProfile(profile: UpdateProfileFormData): Promise<AxiosResponse<GamResponse<BaseUserInfo>>> {
    return await this.httpClient.put(UserEndpoint.USERS, {
      data: profile,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async validateUsername(username: string): Promise<AxiosResponse<GamResponse<ValidateUserDto>>> {
    return this.httpClient.get<ValidateUserDto>(UserEndpoint.VALIDATE_USERNAME, {
      config: {
        params: {
          username,
        },
      },
    });
  }
}
