import type { GeoLocation } from '@/core/data/location/location.interface';
import type { GetCoords, GetTableParams, LoadItemsData, Result } from '@/core/network/http/httpClient.interface';
import { useIntersectStore } from '@/stores/intersect.store';
import { useMapStore } from '@/stores/map.store';
import type { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import { defineStore, storeToRefs } from 'pinia';
import { ref, type Ref } from 'vue';

export const useTableStore = <T extends { id: string }>(listId: GamListId) =>
  defineStore(`${GamStoreId.TABLE}-${listId}`, () => {
    const intersectStore = useIntersectStore();
    const mapStore = useMapStore();
    const isLoading = ref<boolean>(true);
    const itemList = ref<T[] | null>(null) as Ref<T[] | null>;
    const selectedItem = ref<T | null>(null) as Ref<T | null>;
    const selectedIndex = ref<number>(0);
    const addMode = ref<boolean>(false);
    const detailsOpened = ref<boolean>(false);
    const tableParams = ref<GetTableParams>({
      afterCursor: null,
    });
    const loadCallback = ref<((listId: GamListId) => Promise<Result<T[]>>) | null>(null);
    const coordinates = ref<GetCoords>();

    const setAddMode = (state: boolean) => {
      addMode.value = state;
    };

    const setLoadCallback = (callback: (listId: GamListId) => Promise<Result<T[]>>) => {
      loadCallback.value = callback;
    };

    const setLoadingState = (state: boolean) => {
      isLoading.value = state;
    };

    const setCursor = (cursor?: string | null): void => {
      tableParams.value.afterCursor = cursor;
    };

    const setSearchQuery = async (data?: LoadItemsData): Promise<void> => {
      tableParams.value.search = data?.query;
      if (data?.query !== undefined && !data?.preventLoad) {
        await loadItems(data);
      }
    };

    const setLocationParam = (location?: GeoLocation): void => {
      if (location) {
        coordinates.value = {
          latitude: location.lat || undefined,
          longitude: location.lng || undefined,
        };
      } else {
        const { userLocation } = storeToRefs(mapStore);
        coordinates.value = {
          latitude: userLocation.value?.location.lat,
          longitude: userLocation.value?.location.lng,
        };
      }
    };

    const getLocationParam = (): GetCoords | undefined => {
      if (tableParams.value.search?.length) {
        return undefined;
      } else {
        return coordinates.value;
      }
    };

    const populateList = (items: T[] | null): void => {
      if (tableParams.value.afterCursor && itemList.value?.length && items) {
        itemList.value = itemList.value?.concat(items);
      } else {
        itemList.value = items;
      }
    };

    const setList = async (resetListAfterLoad?: boolean, selectFirstItem?: boolean): Promise<void> => {
      setLoadingState(true);

      const result = await loadCallback.value?.(listId);

      if (result?.data) {
        if (resetListAfterLoad) resetList();

        populateList(result.data);

        if (!tableParams.value.afterCursor && selectFirstItem) selectItem(0);

        setCursor(result.cursor?.afterCursor);
      }

      setLoaded();

      setLoadingState(false);
    };

    const setSelectedItem = (item: T | null, index: number): void => {
      selectedItem.value = item;
      selectedIndex.value = index;
      // TODO: Is this needed for mobile? It messes up isShowGambit
      // if (!item) detailsOpened.value = false;
    };

    const selectItem = (index: number) => {
      selectedIndex.value = index;
      selectedItem.value = itemList.value?.[index] || null;
      detailsOpened.value = true;
    };

    const loadItems = async (data?: LoadItemsData): Promise<void> => {
      intersectStore.setLoadState(listId, false);
      if (!data?.loadOnInit) resetList(data?.resetSelected);

      // Load images
      await setList(data?.loadOnInit, data?.selectFirstItem);
    };

    const replaceItems = async (items: any[]): Promise<void> => {
      intersectStore.setLoadState(listId, false);

      itemList.value = items;

      setLoaded();

      setLoadingState(false);
    };

    const infiniteLoad = async (): Promise<void> => {
      if (!tableParams.value.afterCursor) return;
      await setList();
    };

    const resetList = (resetSelected?: boolean): void => {
      setCursor(null);
      populateList(null);
      if (resetSelected) setSelectedItem(null, 0);
    };

    const setLoaded = () => {
      intersectStore.setLoadState(listId, true);
    };

    const updateItem = (update: Partial<T>): T | null => {
      if (!itemList.value) return null;

      const itemIndex = itemList.value.findIndex((item) => item.id === update.id);
      if (itemIndex === -1) {
        return null;
      }

      const tempData = itemList.value[itemIndex];
      Object.entries(update).forEach(([key, value]) => {
        tempData[key as keyof T] = value;
      });

      return tempData;
    };

    const addItem = (item: T) => {
      itemList.value?.push(item);
    };

    const removeItem = (index: number) => {
      itemList.value?.splice(index, 1);
    };

    return {
      isLoading,
      itemList,
      selectedItem,
      selectedIndex,
      detailsOpened,
      addMode,
      setAddMode,
      tableParams,
      setLoadingState,
      setCursor,
      setSearchQuery,
      setList,
      setSelectedItem,
      selectItem,
      resetList,
      replaceItems,
      loadItems,
      infiniteLoad,
      setLoadCallback,
      setLocationParam,
      getLocationParam,
      setLoaded,
      addItem,
      removeItem,
      updateItem,
    };
  });
