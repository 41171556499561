export enum GamNavVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum GamNavSize {
  REGULAR = 'regular',
  SMALL = 'small',
}

export enum GamNavMyProfileSection {
  GENERAL = 'general',
  RATINGS = 'ratings',
  AVAILABILITY = 'availability',
  NOTIFICATION = 'notification',
  PLAN = 'plan',
  PAYMENT = 'payment',
  BLOCKED = 'blocked',
}

export enum GamNavProfileLinks {
  TERMS = 'terms',
  PRIVACY = 'privacy',
  CONTACT = 'contact',
  APP_STORE = 'appstore',
  SOCIAL = 'social',
}

export enum GamLinksType {
  STATIC = 'static',
  EXTERNAL = 'external',
  MAIL = 'mail',
}
