import type ScrollOverflow from '@/views/components/ScrollOverflow.vue';
import { GamListId } from '@/views/composables/constants/components/gamIntersect.constants';
import { GamStoreId } from '@/views/composables/constants/main/store.constants';
import type { GamIntersectItem } from '@/views/composables/models/components/GamIntersect';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useIntersectStore = defineStore(GamStoreId.INTERSECT, () => {
  const intersectList = ref<{ [key: string]: GamIntersectItem | undefined }>({});
  const addIntersect = (id: GamListId, scroll?: typeof ScrollOverflow, isLoaded?: boolean) => {
    intersectList.value[id] = {
      scrollbar: scroll,
      loaded: isLoaded || false,
    };
  };

  const setLoadState = (id: GamListId, loaded: boolean) => {
    const intersectItem = intersectList.value[id];
    if (!intersectItem) return;
    intersectItem.loaded = loaded;
  };

  const isLoaded = (id: GamListId): boolean => {
    const intersectItem = intersectList.value[id];
    if (!intersectItem) return false;
    return intersectItem.loaded;
  };

  const removeIntersect = (id: GamListId): void => {
    if (!intersectList.value[id]) return;
    intersectList.value[id] = undefined;
  };

  return {
    intersectList,
    addIntersect,
    setLoadState,
    removeIntersect,
    isLoaded,
  };
});
